import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Page from "../../layouts/main";
import "./notFound.scss";

@inject("miscStore")
@observer
class NotFound extends Component {
  componentDidMount() {
    this.props.miscStore.setTitle("Page Not Found");
    this.props.miscStore.setHeaderButtons({ back: true, settings: true });
  }

  render() {
    return <Page>404 | Page not found</Page>;
  }
}

export default NotFound;
