import React, { Component } from "react";
import { Input, Button, Message } from "semantic-ui-react";
//import { createUser } from "../../libs/users";
import Page from "../../layouts/main";
//import "./accountSettings.scss";
import { inject, observer } from "mobx-react";

@inject("miscStore")
@observer
class ChangePassword extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Account Settings");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
    }

    state = {
        email: "",
        password: "",
        isPasswordVisible: false,
        isErrorShow: false,
        isLoading: false,
        firstName: "",
        lastName: "",
        phoneNumber: "",

    };

    handlePasswordToggle() {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }


    handleCreateClick() {
        // let userCreated = false;
        this.setState({ isLoading: true, isErrorShow: false });

        setTimeout(() => {
        }, 1000);
    }


    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: * are required cases."
            />
        );
    }

    renderInputs() {
        const { isPasswordVisible, isLoading, isErrorShow } = this.state;
        return (

            <div className="Signup_form">
                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >Phone Number</span>
                    <Input
                        fluid
                        icon="phone"
                        iconPosition="left"
                        placeholder=""
                        onChange={(e, { value }) => this.setState({ phoneNumber: value })}
                    />
                </div>
                <div className="Signup_blockdiv">


                    <span className="Signup_blockdiv_text" >Old Password</span>
                    <Input
                        fluid
                        icon="key"
                        iconPosition="left"
                        placeholder=""
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e, { value }) => this.setState({ password: value })}
                        action={
                            <Button
                                circular
                                color="teal"
                                icon={isPasswordVisible ? "eye slash" : "eye"}
                                onClick={() => this.handlePasswordToggle()}
                            />
                        }
                    />

                    <span className="Signup_blockdiv_text" >New Password</span>
                    <Input
                        fluid
                        icon="key"
                        iconPosition="left"
                        placeholder=""
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e, { value }) => this.setState({ password: value })}
                        action={
                            <Button
                                circular
                                color="teal"
                                icon={isPasswordVisible ? "eye slash" : "eye"}
                                onClick={() => this.handlePasswordToggle()}
                            />
                        }
                    />
                    <span className="Signup_blockdiv_text" >Confirm New Password</span>
                    <Input
                        fluid
                        icon="key"
                        iconPosition="left"
                        placeholder=""
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e, { value }) => this.setState({ password: value })}
                        action={
                            <Button
                                circular
                                color="teal"
                                icon={isPasswordVisible ? "eye slash" : "eye"}
                                onClick={() => this.handlePasswordToggle()}
                            />
                        }
                    />
                </div>
                {isErrorShow && this.showErrorMessage()}

                    <div className="Signup__button">
                        <Button
                            fluid
                            primary
                            loading={isLoading}
                            onClick={() => !isLoading && this.handleCreateClick()}
                        >
                            Save
          </Button>
                 
                </div>

            </div >
        )
    }


    render() {
        return (
            <Page className="Signup">
                <div className="Signup__content">
                    {this.renderInputs()}

                </div>
            </Page>);
    }
}

export default ChangePassword;
