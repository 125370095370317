import React from "react";
import { Button, Header, Modal, Icon } from "semantic-ui-react";
import "./CameraFeedModal.scss";

const CameraFeedModal = ({ open, onClose, onCount, deviceName, feedLink }) => {
  let unsecureFeedLink = "";

  if (feedLink) {
    unsecureFeedLink = feedLink.replace("https://", "http://");
  }

  return (
    <Modal
      open={true}
      basic
      size="small"
      closeIcon
      onClose={onClose}
      className="CameraFeedModal"
    >
      <Header icon="video" content={`Video Feed for ${deviceName}`} />
      <Modal.Content>
        <iframe
          src={`${unsecureFeedLink}/`}
          className="screen-placeholder"
          scrolling="no"
        />
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="blue" onClick={onCount}>
          <Icon name="bug" /> Count
        </Button>
        <Button basic color="red" inverted onClick={onClose}>
          <Icon name="remove" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CameraFeedModal;
