import React, { Component } from "react";
import { Button, Modal, Checkbox } from "semantic-ui-react";
import './selectCropModal.scss';

class selectCropModal extends Component {
    //this.props.values.indexOf('potatoes')>-1? true: false,
    state = {
        potatoes: false,
        cherries: false,
        cucumbers: false,
        lemons: false,
        tomatoes: false,
        apples: false,
        grapes: false,
        avocado: false,
        crops: []
    }

    render() {
        const { open, onClose } = this.props;
        
        return (

            <Modal open={open} onClose={onClose}
                className="selectCropModal"
                >
                <Modal.Header className="selectCropModal_Header">Select Crop</Modal.Header>
                <Modal.Content>
                    <div className="selectCropModal_flexDiv">
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Potatoes"
                                checked={this.state.potatoes}
                                onChange={(e, { checked, label }) => { this.setState({ potatoes: checked }) }}
                            />
                        </div>
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Cherries"
                                checked={this.state.cherries}
                                onChange={(e, { checked }) => { this.setState({ cherries: checked }) }}
                            />
                                </div>
                        </div>
                    </Modal.Content>

                <Modal.Content>
                    <div className="selectCropModal_flexDiv">
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Cucumbers"
                                checked={this.state.cucumbers}
                                onChange={(e, { checked }) => { this.setState({ cucumbers: checked }) }}
                            />
                        </div>
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Lemons"
                                checked={this.state.lemons}
                                onChange={(e, { checked }) => { this.setState({ lemons: checked }) }}
                            />
                        </div>
                    </div>
                </Modal.Content>

                <Modal.Content>
                    <div className="selectCropModal_flexDiv">
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Tomatoes"
                                checked={this.state.tomatoes}
                                onChange={(e, { checked }) => { this.setState({ tomatoes: checked }) }}
                            />
                        </div>
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Apples"
                                checked={this.state.apples}
                                onChange={(e, { checked }) => { this.setState({ apples: checked }) }}
                            />
                        </div>
                    </div>
                </Modal.Content>

                <Modal.Content>
                    <div className="selectCropModal_flexDiv">
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Grapes"
                                checked={this.state.grapes}
                                onChange={(e, { checked }) => { this.setState({ grapes: checked }) }}
                            />
                        </div>
                        <div className="selectCropModal_checkBox">
                            <Checkbox
                                label="Avocado"
                                checked={this.state.avocado}
                                onChange={(e, { checked }) => { this.setState({ avocado: checked }) }}
                            />
                        </div>
                    </div>
                </Modal.Content>
                <Button
                    className="selectCropModal_Button"
                    onClick={() => {
                        var crops = [];
                        if (this.state.potatoes) { crops.push('potatoes') }
                        if (this.state.cherries) { crops.push('cherries') }
                        if (this.state.cucumbers) { crops.push('cucumbers') }
                        if (this.state.lemons) { crops.push('lemons') }
                        if (this.state.tomatoes) { crops.push('tomatoes') }
                        if (this.state.apples) { crops.push('apples') }
                        if (this.state.grapes) { crops.push('grapes') }
                        if (this.state.avocado) { crops.push('avocado') }
                        onClose(crops);
                    }}
                > <span className="selectCropModal_Button_Text">DONE</span>
                </Button>
                <Modal.Actions>
              
                </Modal.Actions>
            </Modal>
        );
    }


}

export default selectCropModal;