import React, { Component } from "react";
import Page from "../../layouts/main";
import "./admin.scss";

class Admin extends Component {
  componentDidMount() {

  }

    render() {
        let user = JSON.parse(localStorage.getItem('current_user'));
        return (<Page>This is an ADMIN page
            <div>
                <h1>Welcome {user.firstName} {user.lastName}</h1>
                </div>

            </Page>);
  }
}

export default Admin;
