


export async function connectToESP() {
    let APIresp = "null";
    const endpoint = "http://192.168.4.1";



    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
       headers: {
          'Content-Type': 'application/json'
        }
        
    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });

    return APIresp;
};

//getAvailableWifi , saveWifi

export async function getAvailableWifi() {
    let APIresp = "null";
    const endpoint = "http://192.168.4.1/availablewifi";



    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });

    return APIresp;
};

export async function saveWifiToESP({ ssid, pass }) {
    let APIresp = "null";
    const endpoint = "http://192.168.4.1/savewifi?ssid=" + ssid +"&pass=" + pass;



    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });

    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }
};