import React, { Component } from "react";
import { inject } from "mobx-react";
import Page from "../../layouts/main";
import { Input, Checkbox, List, Button, Message  } from "semantic-ui-react";
import EditDeviceModal from "../../components/Modals/EditDeviceModal/EditDeviceModal";
import { publishToStation } from "../../libs/mqtt";
import { connectToESP , getAvailableWifi , saveWifiToESP } from "../../libs/esp";
import { getDeviceByKey, getConnectedDevicesToStation, getLastUpdated } from "../../libs/devices";
import { getGreenhouseByKey } from "../../libs/greenhouses";
import { CSVLink } from "react-csv";

import Graph from "../../components/canvasJS/Graph"



import { getLatestDS18Temperature, getLatestDHTTemperature, getLatestDHTHumidity, getLatestBHLight, getLatestMoisture1, getLatestMoisture2, getDS18TemperatureByTime, getDHTTemperatureByTime, getDHTHumidityByTime, getLatestLM35Temperature, getLM35TemperatureByTime,getMoisture1ByTime, getMoisture2ByTime, getBHLightByTime } from "../../libs/sensors";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import "./device.scss";


@inject("miscStore")
class Device extends Component {
    state = {
        device: null,
        greenhouse: null,
        openEditModalWithItem: null,
        isLoading: false,
        latestDHTtemperature: null,
        latestDS18temperature: null,
        latestDHThumidity: null,
        latestBHlight: null,
        latestLM35Temperature: null,
        latestMoisture1: null,
        latestMoisture2: null,
        DHTTemperatureList: [],
        DHTHumidityList: [],
        LM35TemperatureList: [],
        DS18Temperature: [],
        Moisture1List: [],
        Moisture2List: [],
        BHLightList: [],
        SensorsValuesStartingTime: Date.now() - 604800000,
        connectedDevices: [],
        lastUpdated: 0,
        temperatureWarning: false,
        DHTOutOfWorkWarning: false,
        DS18OutOfWorkWarning: false,
        LM35OutOfWorkWarning: false,

        espSerialNumber: null,
        ssid: null,
        pass: null,
        changeWiFiRequested: false,
        availableWiFi: [],
        wifiSaved: false,
    };

    //componentWillUnmount() {
    //    // fix Warning: Can't perform a React state update on an unmounted component
    //    this.setState = (state, callback) => {
    //        return;
    //    };
    //}

    componentDidMount() {
        this.setState({ isLoading: true });

        this.props.miscStore.setTitle("Device");
        this.props.miscStore.setHeaderButtons({ back: true, settings: true });


        this.getDevice()
            .then(() => {
                try {

                    this.getGreenhouse(this.state.device.greenhousekey);
                    this.updateLatestTime(this.state.device.serialnumber);

                    if (this.state.device.role === "Node") {


                        console.log("Device: ",this.state.device);
                        if (this.state.device.sensors.DHTtemperature === true) {
                            this.getLatestDHTtemperature();
                            this.getDHTtemperatureByTime(this.state.SensorsValuesStartingTime);
                        }
                        else {
                            this.setState({ latestDHTtemperature: "disabled" });
                        }

                        if (this.state.device.sensors.DHThumidity === true) {
                            this.getLatestDHThumidity();
                            this.getDHThumidityByTime(this.state.SensorsValuesStartingTime);
                        }
                        else {
                            this.setState({ latestDHThumidity: "disabled" });
                        }

                        if (this.state.device.sensors.LM35temperature === true) {
                            this.getLatestLM35Temperature();

                        }
                        else {
                            this.setState({ LM35Temperature: "disabled" });
                        }


                        if (this.state.device.sensors.DS18temperature === true) {
                            this.getLatestDS18temperature();

                        }
                        else {
                            this.setState({ DS18Temperature: "disabled" });
                        }




                        if (this.state.device.sensors.BHVlight === true) {
                            this.getLatestBHlight();
                            this.getBHLightDataByTime(Date.now() - 2629800000);
                        }
                        else {
                            this.setState({ latestBHlight: "disabled" });
                        }

                        if (this.state.device.sensors.Moisture1 === true) {
                            this.getLatestMoisture1();
                            this.getMoistures1DataByTime(Date.now() - 2629800000);
                        }
                        else {
                            this.setState({ latestMoisture1: "disabled" });
                        }

                        if (this.state.device.sensors.Moisture2 === true) {
                            this.getLatestMoisture2();
                            //18144000000
                            this.getMoistures2DataByTime(Date.now() - 2629800000);
                        }
                        else {
                            this.setState({ latestMoisture2: "disabled" });
                        }
                        
                       
                    }
                    else {
                        this.getConncetedDevices();
                    }
                } catch(error){

                    console.log("ERROR: ", error);
                }
            })
            .finally(() => {
               this.setState({ isLoading: false });
            });
        /*  try {} catch
          { }
          finally { this.setState({ isLoading: false }); };
         */
    }


    updateLatestTime = async (serialnumber) => {
        let lastUpdated = await getLastUpdated({ serialnumber });
        this.setState({ lastUpdated: lastUpdated.string });
    }


    changeSensorsStartingDate = async (date) => {
        this.setState({ SensorsValuesStartingTime: Date.now() - date });
        await this.getSensorsDataByTime(this.state.SensorsValuesStartingTime);

    }

    getDevice = async () => {
        let devicekey = this.props.match.params.devicekey;
        this.setState({ device: await getDeviceByKey({ devicekey }) });
        localStorage.setItem("current_device", JSON.stringify(this.state.device));
    }

    getGreenhouse = async (greenhousekey) => {

        this.setState({ greenhouse: await getGreenhouseByKey({ greenhousekey }) });
    }

    getConncetedDevices = async () => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ connectedDevices: await getConnectedDevicesToStation({ serialnumber }) });
    }

    getLatestDS18temperature = async () => {
        let serialnumber = this.state.device.serialnumber;
        let ds18temperature = await getLatestDS18Temperature({ serialnumber });
        console.log('DS18 Temp: ', ds18temperature);
        if (ds18temperature === null || ds18temperature === undefined || ds18temperature.payload.value === "") {

            this.setState({ latestDS18temperature: null });
            this.setState({ DS18OutOfWorkWarning: true });
        }
        else {
            this.setState({ latestDS18temperature: ds18temperature.payload.value });
            //this.setState({ lastUpdated: dhttemperature.time });
            if (this.state.latestDS18temperature > this.state.device.temperatureThreshold) {
                this.setState({ temperatureWarning: true });
            }

            if (this.state.latestDS18temperature > 100) {
                this.setState({ DS18OutOfWorkWarning: true });
            }

        }


    }


    getLatestDHTtemperature = async () => {
        let serialnumber = this.state.device.serialnumber;
        let dhttemperature = await getLatestDHTTemperature({ serialnumber });
        if (dhttemperature === null || dhttemperature === undefined || dhttemperature.payload.value === "") {
           
            this.setState({ latestDHTtemperature: null });
            this.setState({ DHTOutOfWorkWarning: true });
        }
        else {
            this.setState({ latestDHTtemperature: dhttemperature.payload.value });
            //this.setState({ lastUpdated: dhttemperature.time });
            if (this.state.latestDHTtemperature > this.state.device.temperatureThreshold) {
                this.setState({ temperatureWarning: true });
            }

            if (this.state.latestDHTtemperature > 100) {
                this.setState({ DHTOutOfWorkWarning: true });
            }

        }
        

    }

    getLatestDHThumidity = async () => {
        let serialnumber = this.state.device.serialnumber;
        let dhthumidity = await getLatestDHTHumidity({ serialnumber });
        if (dhthumidity === null || dhthumidity === undefined) {
            this.setState({ latestDHThumidity: null });
        } else {
            this.setState({ latestDHThumidity: dhthumidity.payload.value });

            //this.setState({ lastUpdated: dhthumidity.time });
        }
    }

    getLatestBHlight = async () => {
        let serialnumber = this.state.device.serialnumber;
        let bhlight = await getLatestBHLight({ serialnumber });
        if (bhlight === null || bhlight === undefined) {
            this.setState({ latestBHlight: null });
        } else {
            this.setState({ latestBHlight: bhlight.payload.value });

            //this.setState({ lastUpdated: bhlight.time });
        }
    }

    getLatestLM35Temperature = async () => {
        let serialnumber = this.state.device.serialnumber;
        let lm35 = await getLatestLM35Temperature({ serialnumber });
        if (lm35 === null || lm35 === undefined) {
            this.setState({ latestLM35Temperature: null });
            this.setState({ LM35OutOfWorkWarning: true });
        } else {
            this.setState({ LM35Temperature: lm35.payload.value });
            //this.setState({ lastUpdated: lm35.time });

            if (this.state.LM35Temperature > 100 || this.state.LM35Temperature <= 0)
            { this.setState({ LM35OutOfWorkWarning: true }); }
        }
    }

    getLatestMoisture1 = async () => {
        let serialnumber = this.state.device.serialnumber;
        let moisture1 = await getLatestMoisture1({ serialnumber });
        if (moisture1 === null || moisture1 === undefined) {
            this.setState({ latestMoisture1: null });
        } else {
            this.setState({ latestMoisture1: moisture1.payload.value });

           // this.setState({ lastUpdated: moisture1.time });
        }
    }

    getLatestMoisture2 = async () => {
        let serialnumber = this.state.device.serialnumber;
        let moisture2 = await getLatestMoisture2({ serialnumber });
        if (moisture2 === null || moisture2 === undefined) {
            this.setState({ latestMoisture2: null });
        } else {
            this.setState({ latestMoisture2: moisture2.payload.value });

            //this.setState({ lastUpdated: moisture2.time });
        }
    }

    getBHLightDataByTime= async (startingtime) => {
        console.log('GETTING LIGHT DATA');
        let serialnumber = this.state.device.serialnumber;
        this.setState({ BHLightList: await getBHLightByTime({ serialnumber, startingtime }) });
        console.log('DATA' +  this.state.BHLightList);
    }

    getMoistures1DataByTime = async (startingtime) => {
        console.log('GETTING MOISTURE 1 DATA');
        let serialnumber = this.state.device.serialnumber;
        this.setState({ Moisture1List: await getMoisture1ByTime({ serialnumber, startingtime }) });
        console.log('DATA' +  this.state.Moisture1List);
    }
    getMoistures2DataByTime = async (startingtime) => {
        console.log('GETTING MOISTURE 2 DATA');
        let serialnumber = this.state.device.serialnumber;
        this.setState({ Moisture2List: await getMoisture2ByTime({ serialnumber, startingtime }) });
        console.log('DATA' +  this.state.Moisture2List);
    }

    getSensorsDataByTime = async (startingtime) => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ DHTTemperatureList: await getDHTTemperatureByTime({ serialnumber, startingtime }) });
        this.setState({ DHTHumidityList: await getDHTHumidityByTime({ serialnumber, startingtime }) });
     
    }

    getDS18temperatureByTime = async (startingtime) => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ DS18TemperatureList: await getDS18TemperatureByTime({ serialnumber, startingtime }) });
    }

    getDHTtemperatureByTime = async (startingtime) => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ DHTTemperatureList: await getDHTTemperatureByTime({ serialnumber, startingtime }) });
    }

    getDHThumidityByTime = async (startingtime) => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ DHTHumidityList: await getDHTHumidityByTime({ serialnumber, startingtime }) });
    }

    getLM35temperatureByTime = async (startingtime) => {
        let serialnumber = this.state.device.serialnumber;
        this.setState({ LM35TemperatureList: await getLM35TemperatureByTime({ serialnumber, startingtime }) });
    }

    ConvertEpochToDateTime = (epoch) => {
        if (epoch !== undefined) {
            var d = String(new Date(parseInt(epoch)));

            d = d.substring(0, d.indexOf("GMT"));
            return d;
        }
        else {
            return "N/A";
        }
    }


    getDurationFromLastUpdated = () => {
        var totalduration_s = Math.trunc((Date.now() - this.state.lastUpdated) / 1000);
        var duration_d = Math.trunc(totalduration_s / 86400);
        totalduration_s = totalduration_s % 86400;
        var duration_h = Math.trunc(totalduration_s / 3600);
        totalduration_s = totalduration_s % 3600;
        var duration_m = Math.trunc(totalduration_s / 60);
        totalduration_s = totalduration_s % 60;
        var duration_s = totalduration_s;

        var resp = duration_s + " s ";
        if (duration_m > 0) {
            resp = duration_m + " m " + resp;
        }
        if (duration_h > 0) {
            resp = duration_h + " h " + resp;
        }
        if (duration_d > 0) {
            resp = duration_d + " d " + resp;
        }
        return resp;
    }


    toggleCameraModal(boolValue) {
        this.setState({ isCameraModalOpen: boolValue });
    }

    handleMoreOptionsClick = () => {
        this.props.history.replace("/moreoptions/" + this.state.device.devicekey );
    }

    async waitDelay(delay){
        console.log('Waiting');
        return new Promise( res => setTimeout(res, delay) );}

    handleRequestReadingsClick = async () => {
        if (this.state.device.type == 'NodeMcu') {
            await publishToStation({ station: this.state.device.serialnumber, topic: 'IOTREE/Commands', payload: 'RequestReadings' });
        } else {
            if (this.state.device.station != '-') {
            await publishToStation({ station: this.state.device.station, topic: 'IOTREE/Commands', payload: 'RequestReadings' });
            }
        }
        
        await this.waitDelay(25000);
        console.log('DONE Waiting');
        //this.props.history.replace("/devices/" + this.state.device.devicekey );

        //await publishToStation({ station: 'STATION1', topic: 'IOTREE', payload: 'RequestReadings' });


            if (this.state.device.sensors.DHTtemperature === true) {
                this.getLatestDHTtemperature();
            }

            if (this.state.device.sensors.DHThumidity === true) {
                this.getLatestDHThumidity();
            }

            if (this.state.device.sensors.LM35temperature === true) {
                this.getLatestLM35Temperature();

            }


            if (this.state.device.sensors.DS18temperature === true) {
                this.getLatestDS18temperature();

            }




            if (this.state.device.sensors.BHVlight === true) {
                this.getLatestBHlight();

            }

            if (this.state.device.sensors.Moisture1 === true) {
                this.getLatestMoisture1();
            }
            if (this.state.device.sensors.Moisture2 === true) {
                this.getLatestMoisture2();
            }
            
            this.updateLatestTime(this.state.device.serialnumber);
           
        
    }

    renderDeviceInfo(device) {

        if ((device === null) || (device === undefined)) {
            return (<div></div>);
        } else {
            return (<div>
                <List relaxed className="Device1">
                    <List.Item className="Device1__parent">
                        <List.Icon
                            name="dot circle"
                            color={device.status ? "green" : "red"}
                            size="large"
                            verticalAlign="middle"
                        />
                        <List.Content className="Device__content">
                            <List.Header><h1>{device.serialnumber}</h1></List.Header>

                            <List.Description>
                                {device.status ? "Online" : "Offline"}
                            </List.Description>
                            <List.Description>
                                Greenhouse: {this.state.greenhouse ? this.state.greenhouse.name : ""}
                            </List.Description>
                            <List.Description>
                                Role: {device.role}
                            </List.Description>
                            <List.Description>
                                Type: {device.type}
                            </List.Description>
                        </List.Content>
                        <List.Content floated="right" className="Device__content-right">
                            <Button
                                className="GreenhousesList__edit-name"
                                icon="pencil"
                                circular
                                color="orange"
                                size="tiny"
                                onClick={() =>
                                    this.setState({
                                        openEditModalWithItem: {
                                            ...device,
                                            ...this.props.match.params
                                        }
                                    })
                                }
                            />
                        </List.Content>
                    </List.Item>
                </List>



            </div >
            );
        }
    }

    renderSensorsInfo(device) {


        return (
            <div >
                <div className="Device1_flexdiv">
                    <span className="Device1__last-seen">
                        Last Updated:    {this.state.lastUpdated}
                    </span>

                </div>
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Temperature
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.DHTtemperature ? this.state.latestDHTtemperature + " c" : "N/A"}
                    </span>
                </div>
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Humidity
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.DHThumidity ? this.state.latestDHThumidity + " %" : "N/A"}

                    </span>


                </div >
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Temperature 2
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.LM35temperature ? this.state.LM35Temperature + " c" : "N/A"}
                    </span>
                </div>
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Temperature 3
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && console.log(device.sensors.DS18temperature)}
                        {device && device.sensors.DS18temperature ? this.state.latestDS18temperature + " c" : "N/A"}
                    </span>
                </div>
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Moisture 1
                    </span>
                    <span className="Device1_flexdiv_value">
                        {//device && device.sensors.Moisture1 ? this.state.latestMoisture1 == "0" ? "Dry" : "Moist" : "N/A"
                        }
                        {
                            this.state.latestMoisture1 <= 10 ? "مروي و مشبع"
                                : this.state.latestMoisture1 <= 20 ? "مروي و رطب"
                                    : this.state.latestMoisture1 <= 30 ? "ري التربه الرملية"
                                        : this.state.latestMoisture1 <= 50 ? "ري التربه المتوسطة"
                                            : this.state.latestMoisture1 <= 60 ? "ري التربه الطينية المتوسطة"
                                                : this.state.latestMoisture1 <= 100 ? "ري التربه الطينية الثقيلة"
                                                    : this.state.latestMoisture1 <= 200 ? " جاف بشكل خطير"
                                                        : this.state.latestMoisture1 == 250 ? "Sensor is not connected"
                                                            : ""
                        }
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.Moisture1 ? this.state.latestMoisture1 : "N/A"}

                    </span>
                    <CSVLink data={this.state.Moisture1List || []} className="Device1_flexdiv_value" filename={'IOTree Sensors Readings_Moisture1.csv'}>
					<span>Export monthly data</span>
				</CSVLink>
                </div >
                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Moisture 2
                    </span>
                    <span className="Device1_flexdiv_value">
                        {//device && device.sensors.Moisture2 ? this.state.latestMoisture2 == "0" ? "Dry" : "Moist" : "N/A"
                        }
                        {
                            this.state.latestMoisture2 <= 10 ? "مروي و مشبع"
                                : this.state.latestMoisture2 <= 20 ? "مروي و رطب"
                                    : this.state.latestMoisture2 <= 30 ? "ري التربه الرملية"
                                        : this.state.latestMoisture2 <= 50 ? "ري التربه المتوسطة"
                                            : this.state.latestMoisture2 <= 60 ? "ري التربه الطينية المتوسطة"
                                                : this.state.latestMoisture2 <= 100 ? "ري التربه الطينية الثقيلة"
                                                    : this.state.latestMoisture2 <= 200 ? " جاف بشكل خطير"
                                                        : this.state.latestMoisture2 == 250 ? "Sensor is not connected"
                                                            : ""
                        }
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.Moisture2 ? this.state.latestMoisture2 : "N/A"}
                    </span>
                    <CSVLink data={this.state.Moisture2List || []} className="Device1_flexdiv_value" filename={'IOTree Sensors Readings_Moisture2.csv'}>
					<span>Export monthly data</span>
				</CSVLink>
                </div >

                <div className="Device1_flexdiv">
                    <span className="Device1_flexdiv_text">
                        Light Intensity
                    </span>
                    <span className="Device1_flexdiv_value">
                        {device && device.sensors.BHVlight ? this.state.latestBHlight + " lux" : "N/A"}
                    </span>
                    <CSVLink data={this.state.BHLightList || []} className="Device1_flexdiv_value" filename={'IOTree Sensors Readings_Light.csv'}>
					<span>Export monthly data</span>
				</CSVLink>
                </div >

            </div >
        );


    }



    renderDeviceOptions() {
        return (
            <div >
                <div className="Device1_options">

                    <span className="Device1_options_text">Door</span>
                    <Checkbox
                        toggle
                        value="false"
                        onChange={(e, { value }) => { }}
                    />

                </div>
                <div className="Device1_options">
                    <span className="Device1_options_text">Fan</span>
                    <Checkbox
                        toggle
                        value="false"
                        onChange={(e, { value }) => { }}
                    />

                </div>
                <div className="Device1_flexdiv">
                    <div className="Device1_button Device1_flexdiv_text">
                        <Button
                            fluid
                            primary
                            onClick={() => this.handleRequestReadingsClick()
                                }
                        >
                            Request Readings
          </Button>
                    </div>
                    <div className="Device1_button Device1_flexdiv_text">
                        <Button
                            fluid
                            primary
                            onClick={() => this.handleMoreOptionsClick()}
                        >
                            More Options
          </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderModals() {
        console.log('DEVICE ', this.state.device);
        return (
            <EditDeviceModal
                type="Device"
                open={this.state.openEditModalWithItem}
                onClose={() => this.setState({ openEditModalWithItem: null })}
                device={this.state.device}
            />
        );
    }


    showTemperatureWarningMessage() {
        return (
            <Message
                negative
                icon="x"
                header="High Temperature!"
                content="Device temperature is above threshold."
            />
        );
    }

    showDHTWarningMessage() {
        return (
            <Message
                negative
                icon="x"
                header="DHT Out of Work!"
                content="the DHT sensor is out of work."
            />
        );
    }

    showLM35WarningMessage() {
        return (
            <Message
                negative
                icon="x"
                header="LM35 Out of Work!"
                content="the LM35 sensor is out of work."
            />
        );
    }

    renderGraph(temperatures, humidities) {
        return (
            <div> <div className="Device1_graphContainer">
                <Graph class="Device1_graphContainer_Graph" temperatures={temperatures || []} humidities={humidities || []} />





                <div class="Switches">
                    <div class="switch-toggle">
                        <input id="daily" className="switch-toggle_right" label="test" name="state-d" type="radio" onChange={() => { this.changeSensorsStartingDate(86400000) }} />
                        <label for="daily" className="switch-toggle_right" onClick="">Daily</label>

                        <input id="weekly" name="state-d" type="radio" onChange={() => { this.changeSensorsStartingDate(604800000) }} />
                        <label for="weekly" class="disabled" onclick="">Weekly</label>

                        <input id="monthly" name="state-d" type="radio" onChange={() => { this.changeSensorsStartingDate(18144000000) }} />
                        <label for="monthly" className="switch-toggle_left" onclick="">Monthly</label>

                    </div>
                </div>


            </div>
            </div>
        );
    }



    renderStationConnectedDevices() {


        if (!this.state.connectedDevices.length) {

            return <div align='center'><h2>No device Connected</h2></div>
        }
        else {
            return (
                <div align='center'>

                    <h2 >Connected Devices</h2>

                    <table>
                        <thead>
                            <td><h2><u>Address</u></h2></td>
                            <td><h2><u>Serial number</u></h2></td>
                        </thead>
                        <tbody>

                            {this.state.connectedDevices.map(function (item, index) {
                                return <tr><td><h3>{item.AD}</h3></td><td><h3>{item.SN}</h3></td></tr>
                            })}


                        </tbody>

                    </table></div>);
        }

    }



    connectToESP = async () => {

        var resp = await connectToESP();
        console.log(resp);
        if (resp.SerialNumber) {
            this.setState({ espSerialNumber: resp.SerialNumber });
            this.setState({ ssid: resp.currentSSID });
            this.setState({ pass: resp.currentPASS });
            console.log(this.state.espSerialNumber);
        }
        var av = await getAvailableWifi();
        if (av) {
            this.setState({ availableWiFi: av.AvailableWifi });
        }
    }

    saveWifi = async () => {
        await saveWifiToESP({ ssid: this.state.ssid, pass: this.state.pass });
        this.setState({wifiSaved: true});
    }
    renderWiFiSettings() {
        if (this.state.changeWiFiRequested) {
            if (this.state.espSerialNumber === this.state.device.serialnumber) {
                if(this.state.wifiSaved){
                    return (<div>
                         <div className="Signup_flexdiv">
                        <span className="Device1_flexdiv_text" >SSID and password are updated!</span>
                    </div>  
                    <div className="Signup_flexdiv">
                        <span className="Device1_flexdiv_text" >New SSID: {this.state.ssid}</span>
                    </div>
                    </div>);

                }
                else{
                return (<div>
                    <div className="Signup_flexdiv">
                        <span className="Device1_flexdiv_text" >Available WiFi:</span>
                    <ol>
                    {this.state.availableWiFi.map(function (item, index) {
                        return <li className="Device1_flexdiv_text" >{item.SSID}</li>
                    })}
                        </ol>
                        </div>
                    <div className="Signup_flexdiv">
                        <span className="Device1_flexdiv_text" >SSID</span>
                        <Input
                            fluid
                            value={this.state.ssid}
                            onChange={(e, { value }) => this.setState({ ssid: value })}
                        />
                    </div>
                    <div className="Signup_flexdiv">
                        <span className="Device1_flexdiv_text" >Pass</span>
                        <Input
                            fluid
                            value={this.state.pass}
                            onChange={(e, { value }) => this.setState({ pass: value })}
                        />
                    </div>
                    <Button
                        fluid
                        primary
                        onClick={() => this.saveWifi()}
                    >
                        save
          </Button>
                </div>
                )}
            }
            else {
                return (<div>
                        <span className="Device1_flexdiv_text" >
                            Please connect your device wiFi to: </span>
                        <span className="Device1_flexdiv_text" >
                            IOTREE_{this.state.device.serialnumber}
                    </span>
                    </div>
                  )
            }
        }
        else {
            return (<div>
               
            </div>)
        }
    }


    render() {
        const {
            device
        } = this.state;

       
        if ((device !== null) && (device.role === "Station")) {
            return (
                <Page className="Device1" loading={device === null}>

                    {this.renderDeviceInfo(device)}
                    <div className="Device1__content">
                        {this.renderStationConnectedDevices()}

                        <div className="Device1_button Device1_flexdiv_text">
                            <Button
                                fluid
                                primary
                                onClick={() => { this.setState({ changeWiFiRequested: true }); this.connectToESP() }}
                            >
                                change WiFi
          </Button>

                        </div>
                    {this.renderWiFiSettings()}
                        {this.renderModals()}
                        </div>
                </Page>);
        } else {
            return (
                <Page className="Device1" loading={this.state.isLoading}>

                    {this.renderDeviceInfo(device)}
                    <div className="Device1__content">
                        {this.renderSensorsInfo(device)}

                        {this.renderDeviceOptions()}
                        {this.state.temperatureWarning && this.showTemperatureWarningMessage()}
                        {this.state.DHTOutOfWorkWarning && this.showDHTWarningMessage()}
                        {this.state.LM35OutOfWorkWarning && this.showLM35WarningMessage()}
                        {this.renderModals()}
                    </div>

                    {this.renderGraph(this.state.DHTTemperatureList, this.state.DHTHumidityList)}

                </Page>
            );
        }
    }
}

export default Device;
