import React from "react";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";



import "./GreenhousesList.scss";





const GreenhousesList = ({ lists }) => {
    //console.log("GH List:", lists);
    //if (lists.length) {
    //    const devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: lists[1].greenhousekey });
    //    console.log('Device Infos', devicesinfo);
    //}

   
    if (lists.length > 0) {
        console.log(lists);
        return (
            <div className="GreenhousesList">
                {
                    lists.map((item, index) => (

                        <Link key={`greenhouse_${index}`} to={{
                            pathname: `/greenhouses/${item.greenhousekey}`,
                            greenhouse: lists[index]
                        }} >
                            <List.Item className="GreenhousesList_greenhouse">
                                <div>
                                    <List.Icon
                                        name="leaf"
                                        size="large"
                                        verticalAlign="middle"
                                    />
                                </div>
                                <List.Content className="GreenhousesList_content">
                                    <List.Header className="GreenhousesList_header">{item.name}</List.Header>
                                    <List.Description className="GreenhousesList_description">
                                        Location: {item.location.label}
                                    </List.Description>
                                    <List.Description className="GreenhousesList_description">
                                        Number of Devices: {item.devicesList.length}
                                    </List.Description>
                                    <List.Description className="GreenhousesList_description">
                                        Crop: {
                                        item.devicesinfo.crops.map((crop, index) => { if (index == item.devicesinfo.crops.length - 1) {return crop }else {return crop +', ' }})
                                        }
                                    </List.Description>
                                    <List.Description className="GreenhousesList_description">
                                        Last updated: {item.devicesinfo.lastUpdated.string}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </Link>
                    ))}
            </div>
        );
    } else {
        return (<div></div>);
    }
};

export default GreenhousesList;
