import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { BrowserRouter as Router } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "./Toast.scss";

@inject("miscStore")
@observer
class Toast extends Component {
  render() {
    const storeToast = this.props.miscStore.toast;

    if (storeToast.enabled) {
      let icon;

      switch (storeToast.type) {
        case "error":
          icon = "warning circle";
          break;

        case "warning":
          icon = "warning sign";
          break;

        case "success":
          icon = "checkmark";
          break;

        case "info":
        default:
          icon = "info circle";
          break;
      }

      return (
        <div className={`Toast Toast__${storeToast.type}`}>
          <div>
            <Icon name={icon} size="big" className="Toast__icon" />
            <div>
              <span className="Toast__title">{storeToast.title}</span>
              <br />
              <b>{storeToast.text1}</b>
              <br />
              <span>{storeToast.text2}</span>
            </div>
          </div>
          <Icon
            name="remove"
            size="big"
            link
            onClick={() => this.props.miscStore.clearToast()}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Toast;
