import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Button, Modal, Icon, Input, Label } from "semantic-ui-react";
import "./EditModal.scss";

@inject("fireStore")
@observer
class EditModal extends Component {
    state = {
        name: "",
        isConfirmingDelete: false
    };

    saveChanges({ item, type }) {
        const fireStore = this.props.fireStore;

        console.log(item);

        if (this.state.name !== "") {
            if (type === "Location") {
                fireStore.renameLocation({
                    locationKey: item.key,
                    name: this.state.name
                });
            } else if (type === "Greenhouse") {
                fireStore.renameGreenhouse({
                    locationKey: item.locationKey,
                    greenhouseKey: item.greenhouseKey,
                    name: this.state.name
                });
            } else if (type === "Device") {
                fireStore.renameDevice({
                    locationKey: item.locationKey,
                    greenhouseKey: item.greenhouseKey,
                    deviceKey: item.deviceKey,
                    name: this.state.name
                });
            }
        }
    }

    deleteItem({ item, type }) {
        const fireStore = this.props.fireStore;

        if (type === "Location") {
            fireStore.deleteLocationRecursively({
                locationKey: item.key
            });
        } else if (type === "Greenhouse") {
            fireStore.deleteGreenhouseRecursively({
                locationKey: item.locationKey,
                greenhouseKey: item.greenhouseKey
            });
        } else if (type === "Device") {
            fireStore.deleteDeviceRecursively({
                locationKey: item.locationKey,
                greenhouseKey: item.greenhouseKey,
                deviceKey: item.deviceKey
            });
        }
        this.props.history.go(-1);
    }

    render() {
        const { open, onClose, item, type } = this.props;

        if (item) {
            return (
                <Modal open={open} onClose={onClose} closeIcon>
                    <Modal.Header>Edit {item.name}</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Input
                                label="Name"
                                fluid
                                placeholder="Name"
                                defaultValue={item.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                        </Modal.Description>
                    </Modal.Content>
                    {this.state.isConfirmingDelete ? (
                        <Modal.Actions>
                            <Label color="orange" size="large">
                                <Icon name="warning" />
                                Are you sure you want to delete this?
              </Label>
                            <Button
                                disabled="true"
                                color="red"
                                onClick={() => this.setState({ isConfirmingDelete: false })}
                            >
                                <Icon name="remove" /> No
              </Button>
                            <Button
                                disabled="true"
                                color="green"
                                onClick={() => {
                                    this.deleteItem({ item, type });
                                    onClose();
                                }}
                            >
                                <Icon name="checkmark" /> Yes
              </Button>
                        </Modal.Actions>
                    ) : (
                            <Modal.Actions>
                                <Button
                                    color="red"
                                    onClick={() => this.setState({ isConfirmingDelete: true })}
                                >
                                    <Icon name="trash" /> Delete
              </Button>
                                <Button
                                    color="green"
                                    onClick={() => {
                                        this.saveChanges({ item, type });
                                        onClose();
                                    }}
                                >
                                    <Icon name="pencil" /> Save
              </Button>
                            </Modal.Actions>
                        )}
                </Modal>
            );
        }

        return null;
    }
}

export default withRouter(EditModal);
