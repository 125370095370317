import React, { Component } from "react";
import { Button, Modal, Icon, Input, Message, Checkbox, Dropdown } from "semantic-ui-react";
import { updateDevice } from "../../../libs/devices";
import typesList from "../../../fixtures/typesList";
import rolesList from "../../../fixtures/rolesList";

class AddGreenhouseModal extends Component {
    state = {
        devicekey: "",
        serialnumber: "",
        location: "",
        role: "",
        type: "",
        DHTtemperature: false,
        DHThumidity: false,
        LM35Temperature: false,
        BHVlight: false,
        Moisture1: false,
        Moisture2: false,
        DS18temperature: false,
        isErrorShow: false,
        isLoading: false
    };

    componentWillReceiveProps() {
        if (this.props.device) {
            var device = this.props.device;
            this.setState({
                devicekey: device.devicekey,
                serialnumber: device.serialnumber
            });
            console.log(device.sensors);
            this.setState({
                DHTtemperature: device.sensors.DHTtemperature,
                DHThumidity: device.sensors.DHThumidity,
                LM35Temperature: device.sensors.LM35temperature,
                BHVlight: device.sensors.BHVlight,
                Moisture1: device.sensors.Moisture1,
                Moisture2: device.sensors.Moisture2,
                DS18temperature: device.sensors.DS18temperature
            });
        }


    }

    handleSaveClick() {
        this.setState({ isLoading: true, isErrorShow: false });

        setTimeout(() => {
            console.log(this.state.LM35Temperature);
            updateDevice({
                devicekey: this.state.devicekey,
                DHTtemperature: this.state.DHTtemperature === null ? false : this.state.DHTtemperature,
                DHThumidity: this.state.DHThumidity === null ? false : this.state.DHThumidity,
                BHVlight: this.state.BHVlight === null ? false : this.state.BHVlight,
                LM35Temperature: this.state.LM35Temperature === null ? false : this.state.LM35Temperature,
                Moisture1: this.state.Moisture1 === null ? false : this.state.Moisture1,
                Moisture2: this.state.Moisture2 === null ? false : this.state.Moisture2,
                DS18temperature: this.state.DS18temperature === null ? false : this.state.DS18temperature

            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                        this.props.onClose();
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
    }


    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
            />
        );
    }



    render() {
        const { isLoading, isErrorShow } = this.state;
        const { open, onClose, device } = this.props;


        return (


            <Modal open={open} onClose={onClose} closeIcon>

                <Modal.Header>New Device</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <h2>Serial Number: {this.state.serialnumber}</h2>

                        <br />
                        <Checkbox
                            toggle
                            label="Temperature Sensor"
                            checked={this.state.DHTtemperature}
                            defaultChecked={this.state.DHTtemperature}
                            onChange={(e, { checked }) => { console.log("CHANGED", this.state.DHTtemperature); this.setState({ DHTtemperature: checked }) }}
                        />
                        <br />
                        <br />
                        <Checkbox
                            toggle
                            label="Humidity Sensor"
                            checked={this.state.DHThumidity}
                            value={this.state.DHThumidity}
                            onChange={(e, { value }) => { value = !value; this.setState({ DHThumidity: value }) }}
                        />
                        <br />
                        <br />
                        <Checkbox
                            toggle
                            label="Temperature 2 Sensor"
                            checked={this.state.LM35Temperature}
                            value={this.state.LM35Temperature}
                            onChange={(e, { value }) => { value = !value; this.setState({ LM35Temperature: value }) }}
                        /><br />
                        <br />
                        <Checkbox
                            toggle
                            label="Temperature 3 Sensor"
                            checked={this.state.DS18temperature}
                            value={this.state.DS18temperature}
                            onChange={(e, { value }) => { value = !value; this.setState({ DS18temperature: value }) }}
                        /><br />
                        <br />
                        <Checkbox
                            toggle
                            label="Light Sensor"
                            checked={this.state.BHVlight}
                            value={this.state.BHVlight}
                            onChange={(e, { value }) => { value = !value; this.setState({ BHVlight: value }) }}
                        />
                        <br />
                        <br />
                        <Checkbox
                            toggle
                            label="Moisture 1 Sensor"
                            checked={this.state.Moisture1}
                            value={this.state.Moisture1}
                            onChange={(e, { value }) => { value = !value; this.setState({ Moisture1: value }) }}
                        />
                        <br />
                        <br />
                        <Checkbox
                            toggle
                            label="Moisture 2 Sensor"
                            checked={this.state.Moisture2}
                            value={this.state.Moisture2}
                            onChange={(e, { value }) => { value = !value; this.setState({ Moisture2: value }) }}
                        />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    {isErrorShow && this.showErrorMessage()}
                    <Button
                        color="green"
                        onClick={() => {
                            //this.setState({ userkey: usrkey });
                            //this.setState({ greenhousekey: grnhskey });
                            !isLoading && this.handleSaveClick();

                        }}
                    >
                        <Icon name="save" /> Save
          </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


export default AddGreenhouseModal;
