import { observable, action, toJS } from "mobx";

class MiscStore {
  @observable title = "";
  @observable showBackButton = false;
  @observable showSettingsButton = false;
  @observable toast = {
    enabled: false,
    type: "",
    title: "",
    text1: "",
    text2: ""
  };

  constructor(initialData = {}) {
    // this.title = "No Title";
  }

  setTitle(title) {
    this.title = title;
  }

  setHeaderButtons({ back, settings }) {
    this.showBackButton = back;
    this.showSettingsButton = settings;
  }

  showToast(toastInfo) {
    this.toast = {
      enabled: true,
      type: toastInfo.type, // error, warning, success, info
      title: toastInfo.title,
      text1: toastInfo.text1,
      text2: toastInfo.text2
    };
  }

  clearToast() {
    this.toast = {
      enabled: false,
      type: "",
      title: "",
      text1: "",
      text2: ""
    };
  }

  // get readTitle() {
  //   return toJS(this.locations);
  // }
}

export default MiscStore;
