

const sensorsAPIKey = 'tBuKwfAM7Naxz7rzmHqPXaSq9rqifgqK52EBR13x';

export async function getLatestDS18Temperature({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestds18temperature?serialnumber=" + serialnumber;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    return APIresp;

};

export async function getLatestDHTTemperature({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestdhttemperature?serialnumber=" + serialnumber ;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    return APIresp;

};


export async function getLatestDHTHumidity({ serialnumber }) {
    let APIresp = null;
        const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestdhthumidity?serialnumber=" + serialnumber;
        await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': sensorsAPIKey
            }
        })
            .then(results => results.json())
            .then(results => { APIresp = results[0]; });
        console.log(APIresp);
    return APIresp;

};

export async function getLatestLM35Temperature({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestlm35temperature?serialnumber=" + serialnumber;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    console.log(APIresp);
    return APIresp;

};

export async function getLatestBHLight({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestbhlight?serialnumber=" + serialnumber ;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    console.log(APIresp);
    return APIresp;

};


export async function getLatestMoisture1({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestmoisture1?serialnumber=" + serialnumber;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    console.log(APIresp);
    return APIresp;

};


export async function getLatestMoisture2({ serialnumber }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlatestmoisture2?serialnumber=" + serialnumber;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results[0]; });
    console.log(APIresp);
    return APIresp;

};

export async function getDS18TemperatureByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getdstemperaturebytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);
    return APIresp;

};

export async function getDHTTemperatureByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getdhttemperaturebytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);
    return APIresp;

};


export async function getDHTHumidityByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getdhthumiditybytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);
    return APIresp;

};

export async function getLM35TemperatureByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getlm35temperaturebytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);
    return APIresp;

};

export async function getBHLightByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getbhlightbytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
        
    let lightData = [];
    try{APIresp.forEach(d => {
        if (d && d.payload && d.payload.value) {
            let da = new Date(d.time).toString();
            lightData.push({
               date: da.substring(0, da.indexOf('GMT')),
                value: parseFloat(d.payload.value)
            });
        } else {
            APIresp.splice(APIresp.indexOf(d), 1);
        }
    });}
    catch{}
return lightData;

};

export async function getMoisture1ByTime({ serialnumber, startingtime }) {
    let APIresp = null;
    console.log('SN', serialnumber);
    console.log('ST', startingtime);
    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getmoisture1bytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });

        let moistureData = [];
        try{APIresp.forEach(d => {
            if (d && d.payload && d.payload.value) {
                let da = new Date(d.time).toString();
                moistureData.push({
                   date: da.substring(0, da.indexOf('GMT')),
                    value: parseFloat(d.payload.value)
                });
            } else {
                APIresp.splice(APIresp.indexOf(d), 1);
            }
        });}
        catch{}
    return moistureData;

};

export async function getMoisture2ByTime({ serialnumber, startingtime }) {
    let APIresp = null;

    const endpoint = "https://6dfls0i0gk.execute-api.eu-west-3.amazonaws.com/sensors/getmoisture2bytime?serialnumber=" + serialnumber + "&startingtime=" + startingtime;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': sensorsAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });

        console.log('TEST:', APIresp);
        let moistureData = [];
        try{
            APIresp.forEach(d => {
            if (d && d.payload && d.payload.value) {
                moistureData.push({
                    x: new Date(d.time),
                    y: parseFloat(d.payload.value)
                });
            } else {
                APIresp.splice(APIresp.indexOf(d), 1);
            }
        });}catch{}
    return moistureData;

};