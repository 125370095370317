export default [
  { key: "akkar", text: "Akkar", value: "akkar" },
  { key: "bchare", text: "Bchare", value: "bchare" },
  { key: "der_tamich", text: "Der Tamich", value: "der_tamich" },
  { key: "hemena", text: "Hemena", value: "hemena" },
  { key: "jbeil", text: "Jbeil", value: "jbeil" },
  { key: "kfardebian", text: "Kfardebian", value: "kfardebian" },
  { key: "saida", text: "Saida", value: "saida" },
  { key: "yaanayel", text: "Taanayel", value: "yaanayel" },
  { key: "zahle", text: "Zahle", value: "zahle" }
];
