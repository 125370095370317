import React, { Component } from "react";
import { Input, Button, Message } from "semantic-ui-react";
import Page from "../../layouts/main";
import "./accountSettings.scss";
import { inject, observer } from "mobx-react";
import { updateUser } from "../../libs/users";

@inject("miscStore")
@observer
class AccountSettings extends Component {
    state = {
        
        isPasswordVisible: false,
        isErrorShow: false,
        isLoading: false,
        firstName: JSON.parse(localStorage.getItem('current_user')).firstName,
        lastName: JSON.parse(localStorage.getItem('current_user')).lastName,
        phoneNumber: JSON.parse(localStorage.getItem('current_user')).phoneNumber,
        email: JSON.parse(localStorage.getItem('current_user')).email,
        userkey: JSON.parse(localStorage.getItem('current_user')).userkey
    };

    componentDidMount() {
        this.props.miscStore.setTitle("Account Settings");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
        
    }





    handleEditFirstNameClick() {
         let userUpdated = false;
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {
            userUpdated = updateUser({
                userkey: this.state.userkey,
               firstName: this.state.firstName
           })
               .then(results => {
                   this.setState({ isLoading: false });
                   if (results) {
                   } else {
                       this.setState({ isErrorShow: true });
                   }
               });
        }, 1000);
    }

    handleEditLastNameClick() {
        let userUpdated = false;
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {
            userUpdated = updateUser({
                userkey: this.state.userkey,
                lastName: this.state.lastName
            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
    }

    handleEditEmailClick() {
        let userUpdated = false;
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {
            userUpdated = updateUser({
                userkey: this.state.userkey,
                email: this.state.email
            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
    }

    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: * are required cases."
            />
        );
    }

    renderInputs() {
        const {  isLoading, isErrorShow } = this.state;
        return (

            <div className="AccountSettings_form">
                <div className="AccountSettings_flexdiv">
                    <span className="AccountSettings_flexdiv_text" >Phone Number</span>
                    <Input
                        fluid
                        icon="phone"
                        iconPosition="left"
                        placeholder=""
                        value={this.state.phoneNumber}
                        onChange={(e, { value }) => this.setState({ phoneNumber: value })}
                    />
                </div>
                <div className="AccountSettings_flexdiv">


                    <span className="AccountSettings_flexdiv_text" >First Name</span>
                    <Input
                        fluid
                        icon="user"
                        iconPosition="left"
                        placeholder=""
                        value={this.state.firstName}
                        onChange={(e, { value }) => this.setState({ firstName: value })}
                    />
                    <Button
                        fluid
                        primary
                        loading={isLoading}
                        onClick={() => !isLoading && this.handleEditFirstNameClick()}
                    >
                        Edit
          </Button>
                </div>
                <div className="AccountSettings_flexdiv">
                    <span className="AccountSettings_flexdiv_text" >Last Name</span>
                    <Input
                        fluid
                        icon="user"
                        iconPosition="left"
                        placeholder=""
                        value={this.state.lastName}
                        onChange={(e, { value }) => this.setState({ lastName: value })}
                    />
                    <Button
                        fluid
                        primary
                        loading={isLoading}
                        onClick={() => !isLoading && this.handleEditLastNameClick()}
                    >
                        Edit
          </Button>
                </div>
                <div className="AccountSettings_flexdiv">
                    <span className="AccountSettings_blockdiv_text" >Email Address</span>
                    </div>
                <div className="AccountSettings_emaildiv">
                  
                    <Input
                        fluid
                        icon="at"
                        iconPosition="left"
                        placeholder=""
                        value={this.state.email}
                        onChange={(e, { value }) => this.setState({ email: value })}
                    />
                    <Button
                        fluid
                        primary
                        loading={isLoading}
                        onClick={() => !isLoading && this.handleEditEmailClick()}
                    >
                        Edit
          </Button>
                </div>
                {isErrorShow && this.showErrorMessage()}

                    <div className="Signup__button">
                        <Button
                            fluid
                            primary
                            loading={isLoading}
                        onClick={() => !isLoading && 
                            this.props.history.replace({ pathname: "/setPassword", phoneNumber: this.state.phoneNumber })
                    }
                        >
                            Change Password
          </Button>
                 
                </div>

            </div >
        )
    }


    render() {
        return (
            <Page className="Signup">
                <div className="Signup__content">
                    {this.renderInputs()}

                </div>
            </Page>);
    }
}

export default AccountSettings;
