import React, { Component } from "react";
import { Input, Button, Message, Dropdown, Checkbox } from "semantic-ui-react";
import Page from "../../layouts/main";
import "./addDevice.scss";
import { inject, observer } from "mobx-react";

import SelectCropModal from "../../components/Modals/selectCropModal/selectCropModal";
import { addDevice_fn } from "../../libs/devices";
import typesList from "../../fixtures/typesList";
import rolesList from "../../fixtures/rolesList";
import typeOfSoilList from "../../fixtures/typeOfSoilList";

@inject("miscStore")
@observer
class addDevice extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Add New Device");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
        console.log(this.props.location.greenhouse);
    }

    state = {
        isSelectCropOpen: false,
        isErrorShow: false,
        isLoading: false,
        plot: this.props.location.greenhouse ? this.props.location.greenhouse : JSON.parse(localStorage.getItem('current_plot')),
        user: JSON.parse(localStorage.getItem('current_user')),
        serialNumber: '',
        deviceType: '',
        deviceName: '',
        role: 'Node',
        type: 'ESP32LoRa',
        typeofSoil: '',
        Camera: false,
        Temperature1: false,
        Tempperature2: false,
        Tempperature3: false,
        Humidity: false,
        Moisture1: false,
        Moisture2: false,
        LightIntensity: false,
        crops: []
    };



    handleAddClick() {
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {
            console.log(this.state.plot);
            addDevice_fn({
                userkey: this.state.user.userkey,
                plotkey: this.state.plot.greenhousekey,
                serialnumber: this.state.serialNumber,
                name: this.state.deviceName,
                type: this.state.type,
                role: this.state.role,
                plotName: this.state.plot.name,
                plotLocation: this.state.plot.location.label,
                typeofSoil: this.state.typeofSoil,
                crops: this.state.crops,
                Camera: this.state.Camera,
                DHTtemperature: this.state.Temperature1,
                DHThumidity: this.state.Humidity,
                LM35temperature: this.state.Temperature2,
                DS18temperature: this.state.Temperature3,
                BHVlight: this.state.LightIntensity,
                Moisture1: this.state.Moisture1,
                Moisture2: this.state.Moisture2
                

            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                        this.props.history.replace("/greenhouses/" + this.state.plot.greenhousekey);
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
        
    }


    renderSelectCropModal() {
        return (
            <SelectCropModal
                values={this.state.crops}
                open={this.state.isSelectCropOpen}
                onClose={(values) => { console.log('CROPS: '+ values); this.setState({crops: values}); this.setState({ isSelectCropOpen: false }) }}
            />);
    }

    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: all cases are required."
            />
        );
    }


    render() {
        const { isLoading, isErrorShow } = this.state;
        return (

            <Page className="AddDevice">
                <div className="AddDevice_content">

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Serial Number</span>
                        <Input
                            fluid
                            placeholder="Serial Number"
                            value={this.state.serialNumber}
                            onChange={(e, { value }) => this.setState({ serialNumber : value })}
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Device Type</span>
                        <Dropdown
                            className="AddDevice_flexdiv_dropdown"
                            fluid
                            placeholder="Select device type"
                            selection
                            search
                            options={typesList}
                            onChange={(e, { value }) => { this.setState({ type: value }) }}
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Device Role</span>
                        <Dropdown
                            className="AddDevice_flexdiv_dropdown"
                            fluid
                            placeholder="Select device type"
                            selection
                            search
                            options={rolesList}
                            onChange={(e, { value }) => { this.setState({ role: value }) }}
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Device Name</span>
                        <Input
                            fluid
                            placeholder="Device Name"
                            value={this.state.deviceName}
                            onChange={(e, { value }) => this.setState({ deviceName: value })}
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Location</span>
                        <Input
                            fluid
                            placeholder=""
                            value={this.state.plot.location? this.state.plot.location.label:''}
                            disabled="true"
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Plot Name</span>
                        <Input
                            fluid
                            placeholder=""
                            value={this.state.plot.name ? this.state.plot.name: ''}
                            disabled="true"
                        />

                    </div>

                    {this.renderSelectCropModal()}
                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Crop</span>
                        <div className="AddDevice_selectCrop">
                        <Button
                                className="AddDevice_selectCrop_button"
                                fluid
                                primary
                                onClick={() => { this.setState({ isSelectCropOpen: true });}}
                        >
                            Select
          </Button>
                            <span className="AddDevice_selectCrop_text" >{this.state.crops.length > 2 ? this.state.crops[0] + ',' + this.state.crops[1] + ',...' : this.state.crops.length > 1 ? this.state.crops[0] + ',' + this.state.crops[1] : this.state.crops.length > 0 ? this.state.crops[0] : ''}</span>
                            </div>
                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Type of Soil</span>
                        <Dropdown
                            className="AddDevice_flexdiv_dropdown"
                            fluid
                            placeholder="Select type of soil"
                            selection
                            search
                            options={typeOfSoilList}
                            onChange={(e, { value }) => { this.setState({ typeofSoil: value }) }}
                        />

                    </div>

                    <div className="AddDevice_flexdiv">
                        <span className="AddDevice_flexdiv_text" >Sensors</span>
                    </div>


                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Camera</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Camera}
                            value={this.setState.Camera}
                            onChange={(e, { value }) => { value = !value; this.setState({ Camera: value }) }}
                        />
                    </div>

                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Humidity</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Humidity}
                            value={this.setState.Humidity}
                            onChange={(e, { value }) => { value = !value; this.setState({ Humidity: value }) }}
                        />
                    </div>

                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Temperature</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Temperature1}
                            value={this.setState.Temperature1}
                            onChange={(e, { value }) => { value = !value; this.setState({ Temperature1: value }) }}
                        />
                    </div>

                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Light Intesity</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.LightIntensity}
                            value={this.setState.LightIntensity}
                            onChange={(e, { value }) => { value = !value; this.setState({ LightIntensity: value }) }}
                        />
                    </div>

                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Temperature (Soil)</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Temperature2}
                            value={this.setState.Temperature2}
                            onChange={(e, { value }) => { value = !value; this.setState({ Temperature2: value }) }}
                        />
                    </div>
                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Temperature (water)</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Temperature3}
                            value={this.setState.Temperature3}
                            onChange={(e, { value }) => { value = !value; this.setState({ Temperature3: value }) }}
                        />
                    </div>
                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Moisture 1</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Moisture1}
                            value={this.setState.Moisture1}
                            onChange={(e, { value }) => { value = !value; this.setState({ Moisture1: value }) }}
                        />
                    </div>

                    <div className="AddDevice_sensorsflexdiv">
                        <span className="AddDevice_sensorsflexdiv_text" >Moisture 2</span>
                        <Checkbox
                            className="AddDevice_sensorsflexdiv_checkbox"
                            toggle
                            checked={this.setState.Moisture2}
                            value={this.setState.Moisture2}
                            onChange={(e, { value }) => { value = !value; this.setState({ Moisture2: value }) }}
                        />
                    </div>

                    {isErrorShow && this.showErrorMessage()}
                    <div className="AddDevice_buttonContainer">
                        <Button
                            className="AddDevice_button"
                            fluid
                            primary
                            loading={isLoading}
                            onClick={() => !isLoading && this.handleAddClick()}
                        >
                            Add
          </Button>
                    </div>
                </div>
            </Page>);
    }
}

export default addDevice;
