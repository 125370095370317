import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button } from "semantic-ui-react";
import Page from "../../layouts/main";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTwitter,
    faInstagram,

    faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import "./settings.scss";

// Images
import logo from "../../static/logo.png";
import flagFrance from "../../static/flag-france.png";
import flagLebanon from "../../static/flag-lebanon.png";
import flagUK from "../../static/flag-uk.png";

const LANGUAGES = [
    { name: "Arabic", code: "ar", flag: flagLebanon },
    { name: "English", code: "en", flag: flagUK },
    { name: "French", code: "fr", flag: flagFrance }
];


@inject("miscStore")
@observer
class Settings extends Component {
    state = {

    };

    async componentDidMount() {
        this.props.miscStore.setTitle("Settings");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
    }

    renderButtons() {
        return (
            <div className="Settings_form">
            <div className="Settings__button">
                <Button
                    fluid
                    primary
                        onClick={() => { this.props.history.replace("/AccountSettings"); }}
                >
                    Account Settings
          </Button>
                </div>
                <div className="Settings__button">
                <Button
                    fluid
                    primary
                        onClick={() => { this.props.history.replace("/DevicesManager"); }}
                >
                    Device Manager
          </Button>
                </div>
            </div>
        );
    }

    renderLanguages() {
        const { selectedLanguage } = this.state;
        return (
            <Fragment>
                <p className="Login__select-language">Select Language</p>
                <div className="Login__languages">
                    {LANGUAGES.map(language => (
                        <div
                            key={`language_${language.code}`}
                            className={`Login__flag${
                                selectedLanguage === language.code
                                    ? " Login__flag--selected"
                                    : " "
                                }`}
                            onClick={() => this.handleLanguageChange(language.code)}
                        >
                            <img src={language.flag} />
                            <p>{language.name}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
    renderContacts() {
        return (
            <div className="social__container">
                <a href="https://www.linkedin.com/company/internetoftrees/" className="facebook social" >
                    <FontAwesomeIcon icon={faLinkedin} size="3x" />
                </a>

                <a href="https://www.facebook.com/IoTree1/" className="facebook social" >
                    <FontAwesomeIcon icon={faFacebook} size="3x" />
                </a>
                <a href="https://www.twitter.com/iotree1" className="twitter social">
                    <FontAwesomeIcon icon={faTwitter} size="3x" />
                </a>
                <a href="https://www.instagram.com/internetoftrees" className="instagram social" >
                    <FontAwesomeIcon icon={faInstagram} size="3x" />
                </a>
                <br></br>
                <span className="Login__text social__text">
                    Call us on <a className="Login__link-text">+961 76 179 910</a>
                    <br></br>
                    <a className="Login__link-text social__text">info@iotreesolutions.com</a>
                </span>
            </div>
        );
    }


    render() {
        return (
            <Page className="Settings__content" loading={false}>
               
                    {this.renderButtons()}
                    {this.renderLanguages()}
                    {this.renderContacts()}

            </Page>
        );
    }
}

export default Settings;
