import { observable, toJS } from "mobx";
import weaved from "../network/weaved";

const MOTION_SUFFIXES = ["_feed", "_web"];

class WeavedStore {
  @observable allDevices = [];
  @observable isLoading = true;
  @observable requestedAddresses = { _feed: null, _web: null };
  @observable chosenWeavedID = null;

  constructor(initialData = {}) {
    this.getAllDevices();
  }

  setLoading(isLoading) {
    this.isLoading = isLoading;
  }

  get readDevices() {
    return toJS(this.allDevices);
  }

  getAllDevices(shouldUpdate) {
    weaved.getAllDevices().then(res => {
      if (res.status === 200) {
        // this.isLoading = false;
        this.allDevices = res.devices;
      }
      this.chosenWeavedID &&
        this.getDeviceAddresses(this.chosenWeavedID, shouldUpdate);
    });
  }

  setWeavedDeviceId(newId, shouldUpdate) {
    console.log("setWeavedDeviceId", newId);
    this.requestedAddresses = { _feed: null, _web: null };
    this.chosenWeavedID = newId;
    this.allDevices.length !== 0 && this.getAllDevices(shouldUpdate);
  }

  getDeviceAddresses(shouldUpdate) {
    this.isLoading = true;
    MOTION_SUFFIXES.map(async suffix => {
      const foundDevice = this.allDevices.find(
        e => e.name === `Pi_Tree_${this.chosenWeavedID}${suffix}`
      );

      await weaved.getDeviceAddress(foundDevice.address).then(res => {
        console.log("res", res);
        if (res.status === 200) {
          console.log(res.connection.proxy);
          this.requestedAddresses[suffix] = res.connection.proxy;
          console.log(this.requestedAddresses);
          this.isLoading = false;
          shouldUpdate && weaved.updateDeviceCode(this.requestedAddresses._web);
        }
      });
    });
  }

  countInsects() {
    weaved.postRequestWithFile(this.requestedAddresses._web);
  }

  updateDevice(weavedId) {
    this.setWeavedDeviceId(weavedId, true);
  }
}

export default WeavedStore;
