import React, { Fragment, Component } from "react";
import CanvasJSReact from './canvasjs.react';
import { Button } from "semantic-ui-react";
import { CSVLink } from "react-csv";

//const Temp_COLOR = "#e80707";
//const Hum_COLOR = "#6D78AD";
const Temp_COLOR = "#6D78AD";
const Hum_COLOR = "#51CDA0";


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const organizeData = data => {

	var dataToReturn = [];
	data.forEach(d => {
		if (d && d.payload && d.payload.value) {
			dataToReturn.push({
				x: new Date(d.time),
				y: parseFloat(d.payload.value)
			});
		} else {
			data.splice(data.indexOf(d), 1);
		}
	});

	return dataToReturn;
};


class chart extends Component {

	constructor() {
		super();
		this.toggleDataSeries = this.toggleDataSeries.bind(this);

	}

	toggleDataSeries(e) {
		if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		}
		else {
			e.dataSeries.visible = true;
		}
		this.chart.render();
	}

	render() {
		var csvData = [];
		var temperatures = this.props.temperatures;
		var humidities = this.props.humidities;

		//console.log(this.props.temperatures );

		if (temperatures && temperatures.length != 0) {

			temperatures = organizeData(temperatures);
		}

		if (humidities && humidities.length != 0) {

			humidities = organizeData(humidities);
		}

		

		if (this.chart && this.chart.options.data[0] ) {//
			csvData.push(["Temperatures"]);
			//console.log(this.chart.options.data[0]);
			temperatures.forEach(data => {
				if (data) {
					csvData.push([data.x, data.y]);
				} else {
					temperatures.splice(temperatures.indexOf(data), 1);
				}
			});
		}
		if (this.chart && this.chart.options.data[1] ) {
			//&& this.chart.options.data[1].visible
			csvData.push(["Humidities"]);
			
			humidities.forEach(data => {
				if (data) {
					csvData.push([data.x, data.y]);
				} else {
					humidities.splice(humidities.indexOf(data), 1);
				}
			});
		
		}

		//console.log("CSV:", csvData);

		//console.log(temperatures);
		//console.log(humidities);

		const options = {
			theme: "light2",
			animationEnabled: true,
			exportEnabled: true,
			/*title: {
				text: "Units Sold VS Profit"
			},
			subtitles: [{
				text: "Click Legend to Hide or Unhide Data Series"
			}],*/
			axisX: {
			},
			axisY: {
				title: "temperature",
				suffix: "c",
				titleFontColor: Temp_COLOR,
				lineColor: Temp_COLOR,
				labelFontColor: Temp_COLOR,
				tickColor: Temp_COLOR,
				includeZero: false
			},
			axisY2: {
				title: "humidity",
				suffix: "%",
				titleFontColor: Hum_COLOR,
				lineColor: Hum_COLOR,
				labelFontColor: Hum_COLOR,
				tickColor: Hum_COLOR,
				includeZero: false
			},
			toolTip: {
				shared: true
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toggleDataSeries
			},
			data: [{
				type: "line",
				name: "temperatures",
				showInLegend: true,
				color: Temp_COLOR,
				xValueFormatString: "DD MMM YYYY",
				yValueFormatString: "#0.0c",
				dataPoints: temperatures

			},
			{
				type: "line",
				name: "humidities",
				axisYType: "secondary",
				showInLegend: true,
				color: Hum_COLOR,
				xValueFormatString: "DD MMM YYYY",
				yValueFormatString: "0.0",
				dataPoints: humidities

			}]
		}


		return (
			<div>
				<CSVLink data={csvData} className="Device1_ExportButton" filename={`IOTree Sensors Readings.csv`}>
					<Button color="blue" circular icon="download" />
				</CSVLink>


				<CanvasJSChart options={options}
					onRef={ref => this.chart = ref}
				/>
				{/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
			</div>
		);
	}

}


export default chart;      