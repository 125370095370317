import React, { Component } from "react";
import { Input, Button, Message, Dropdown, Checkbox } from "semantic-ui-react";
import Page from "../../layouts/main";
import "./editDevice.scss";
import { inject, observer } from "mobx-react";

import SelectCropModal from "../../components/Modals/selectCropModal/selectCropModal";
import { updateDevice } from "../../libs/devices";
import typesList from "../../fixtures/typesList";
import rolesList from "../../fixtures/rolesList";
import typeOfSoilList from "../../fixtures/typeOfSoilList";

@inject("miscStore")
@observer
class editDevice extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Edit Device");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
        console.log(this.props.location.device);
        if (this.props.location.device) {
            this.setState({
                serialNumber: this.props.location.device.serialnumber,
                deviceType: this.props.location.device.type,
                deviceName: this.props.location.device.name,
                role: this.props.location.device.role,
                type: this.props.location.device.type,
                typeofSoil: this.props.location.device.typeofSoil,
                Camera: this.props.location.device.sensors.Camera,
                Temperature1: this.props.location.device.sensors.DHTtemperature,
                Tempperature2: this.props.location.device.sensors.LM35temperature,
                Humidity: this.props.location.device.sensors.DHThumidity,
                Moisture1: this.props.location.device.sensors.Moisture1,
                Moisture2: this.props.location.device.sensors.Moisture2,
                LightIntensity: this.props.location.device.sensors.BHVlight,
            });
        }
    }

    state = {
        isSelectCropOpen: false,
        isErrorShow: false,
        isLoading: false,
        user: JSON.parse(localStorage.getItem('current_user')),
        device: this.props.location.device ? this.props.location.device : null,
        /*
        plot: this.props.location.greenhouse ? this.props.location.greenhouse : JSON.parse(localStorage.getItem('current_plot')),
        */
        serialNumber: '',
        deviceType: '',
        deviceName: '',
        role: 'Node',
        type: 'ESP32LoRa',
        typeofSoil: '',
        Camera: false,
        Temperature1: false,
        Tempperature2: false,
        Humidity: false,
        Moisture1: false,
        Moisture2: false,
        LightIntensity: false,
        crops: []
       
    };



    handleEditClick() {
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {

            updateDevice({
                userkey: this.state.user.userkey,
                devicekey:this.state.device.devicekey,
                serialnumber: this.state.serialNumber,
                name: this.state.deviceName,
                type: this.state.type,
                role: this.state.role,
                typeofSoil: this.state.typeofSoil,
                crops: this.state.crops,
                Camera: this.state.Camera,
                DHTtemperature: this.state.Temperature1,
                DHThumidity: this.state.Humidity,
                LM35Temperature: this.state.Temperature2,
                BHVlight: this.state.LightIntensity,
                Moisture1: this.state.Moisture1,
                Moisture2: this.state.Moisture2
                

            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                        this.props.history.replace("/greenhouses/" + this.state.device.greenhousekey);
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
        this.setState({ isLoading: false });
    }


    renderSelectCropModal() {
        return (
            <SelectCropModal
                values={this.state.crops}
                open={this.state.isSelectCropOpen}
                onClose={(values) => { this.setState({crops: values}); this.setState({ isSelectCropOpen: false }) }}
            />);
    }

    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: all cases are required."
            />
        );
    }


    render() {
        const { isLoading, isErrorShow } = this.state;
        console.log(this.state.device);
        if (this.state.device != undefined) {
            return (

                <Page className="EditDevice">
                    <div className="EditDevice_content">

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Serial Number</span>
                            <Input
                                disabled
                                fluid
                                placeholder="Serial Number"
                                value={this.state.serialNumber}
                                onChange={(e, { value }) => this.setState({ serialNumber: value })}
                            />

                        </div>

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Device Type</span>
                            <Dropdown
                                className="EditDevice_flexdiv_dropdown"
                                fluid
                                placeholder={this.state.type}
                                selection
                                search
                                options={typesList}
                                onChange={(e, { value }) => { this.setState({ type: value }) }}
                            />

                        </div>

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Device Role</span>
                            <Dropdown
                                className="EditDevice_flexdiv_dropdown"
                                fluid
                                placeholder={this.state.role}
                                selection
                                search
                                options={rolesList}
                                onChange={(e, { value }) => { this.setState({ role: value }) }}
                            />

                        </div>

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Device Name</span>
                            <Input
                                disabled
                                fluid
                                placeholder="Device Name"
                                value={this.state.deviceName}
                                onChange={(e, { value }) => this.setState({ deviceName: value })}
                            />

                        </div>



                        {this.renderSelectCropModal()}
                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Crop</span>
                            <div className="EditDevice_selectCrop">
                                <Button
                                    className="EditDevice_selectCrop_button"
                                    fluid
                                    primary
                                    onClick={() => { this.setState({ isSelectCropOpen: true }); console.log(this.state.isSelectCropOpen) }}
                                >
                                    Select
          </Button>
                                <span className="EditDevice_selectCrop_text" >{this.state.crops.length > 2 ? this.state.crops[0] + ',' + this.state.crops[1] + ',...' : this.state.crops.length > 1 ? this.state.crops[0] + ',' + this.state.crops[1] : this.state.crops.length > 0 ? this.state.crops[0] : ''}</span>
                            </div>
                        </div>

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Type of Soil</span>
                            <Dropdown
                                className="EditDevice_flexdiv_dropdown"
                                fluid
                                placeholder={this.state.typeOfSoil}
                                selection
                                search
                                options={typeOfSoilList}
                                onChange={(e, { value }) => { this.setState({ typeofSoil: value }) }}
                            />

                        </div>

                        <div className="EditDevice_flexdiv">
                            <span className="EditDevice_flexdiv_text" >Sensors</span>
                        </div>


                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Camera</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Camera}
                                value={this.setState.Camera}
                                onChange={(e, { value }) => { value = !value; this.setState({ Camera: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Humidity</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Humidity}
                                value={this.setState.Humidity}
                                onChange={(e, { value }) => { value = !value; this.setState({ Humidity: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Temperature</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Temperature1}
                                value={this.setState.Temperature1}
                                onChange={(e, { value }) => { value = !value; this.setState({ Temperature1: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Light Intesity</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.LightIntensity}
                                value={this.setState.LightIntensity}
                                onChange={(e, { value }) => { value = !value; this.setState({ LightIntensity: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Temperature (Soil)</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Temperature2}
                                value={this.setState.Temperature2}
                                onChange={(e, { value }) => { value = !value; this.setState({ Temperature2: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Moisture 1</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Moisture1}
                                value={this.setState.Moisture1}
                                onChange={(e, { value }) => { value = !value; this.setState({ Moisture1: value }) }}
                            />
                        </div>

                        <div className="EditDevice_sensorsflexdiv">
                            <span className="EditDevice_sensorsflexdiv_text" >Moisture 2</span>
                            <Checkbox
                                className="EditDevice_sensorsflexdiv_checkbox"
                                toggle
                                checked={this.setState.Moisture2}
                                value={this.setState.Moisture2}
                                onChange={(e, { value }) => { value = !value; this.setState({ Moisture2: value }) }}
                            />
                        </div>

                        {isErrorShow && this.showErrorMessage()}
                        <div className="EditdDevice_buttonContainer">
                            <Button
                                className="EditDevice_button"
                                fluid
                                primary
                                loading={isLoading}
                                onClick={() => !isLoading && this.handleEditClick()}
                            >
                                Edit
          </Button>
                        </div>
                    </div>
                </Page>);
        } else {
            return (<Page className="EditDevice"><div /></Page>);
        }
    }
}

export default editDevice;
