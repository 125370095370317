import React, { Component } from "react";
import { Input, Button, Message, Dropdown } from "semantic-ui-react";
import Page from "../../layouts/main";
import "./addGreenhouse.scss";
import { inject, observer } from "mobx-react";
import { addGreenhouse } from "../../libs/greenhouses";

import citiesList from "../../static/cities/citiesLB.json";

@inject("miscStore")
@observer
class addGreenHouse extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Add a Plot");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
        let list = citiesList.map((value) => {
            return (
                { //key: value.text, 
                    value: value.text, text: value.text
                })
        })
        this.setState({ userKey: this.props.location.userkey });
        this.setState({ citiesList: list });
    }
    

    state = {
        userKey: null,
        location: "",
        name: "",
        isErrorShow: false,
        isLoading: false,
        citiesList: citiesList

    };


    handleAddClick() {
        this.setState({ isLoading: true, isErrorShow: false });
        setTimeout(() => {
            addGreenhouse({
                userkey: this.props.location.userkey,
                name: this.state.name,
                location: this.state.location
            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                         this.props.history.replace("/user");
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
    }


    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: all cases are required."
            />
        );
    }


    render() {
        const { isLoading, isErrorShow } = this.state;
        return (

            <Page className="AddGreenHouse">
                <div className="AddGreenHouse_content">
                    <div className="AddGreenHouse_blockdiv">
                        <span className="AddGreenHouse_blockdiv_text" >Plot Name</span>
                    <Input
                        fluid
                        placeholder="Enter Plot name"
                        onChange={(e, { value }) => this.setState({ name: value })}
                    />

                        <span className="AddGreenHouse_blockdiv_text" >Plot Location</span>
                        <Dropdown
                            placeholder='Select City'
                            fluid
                            search
                            selection
                            options={this.state.citiesList}
                            //selectedLabel={this.state.location}
                            onChange={(e, { value }) => this.setState({ location: value })}
                        />
                    </div>

                   

                    {isErrorShow && this.showErrorMessage()}
                    <div className="AddGreenHouse_buttonContainer">
                        <Button
                            className="AddGreenHouse_button"
                            fluid
                            primary
                            loading={isLoading}
                            onClick={() => !isLoading && this.handleAddClick()}
                        >
                            Add
          </Button>
                    </div>
                    </div>
            </Page>);
    }
}

export default addGreenHouse;
