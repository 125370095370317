import axios from "axios";

const remoteIt = {
  devKey: "QUE1OTM5NzItMThDNy00NENBLThFQjMtMTNCNDFDMTMwMUU4",
  username: "jo.taouk5@gmail.com",
  password: "remoteIt$IOTree"
};

class AxiosCalls {
  postRequestWithFile(raspAddress) {
    axios.post(`${raspAddress}/motion/call_api.php`);
  }

  updateDeviceCode(raspAddress) {
    axios.post(`${raspAddress}/motion/update_source_code.php`);
  }

  getAllDevices() {
    return new Promise((resolve, reject) => {
      let username = remoteIt.username;
      let password = remoteIt.password;
      let developerkey = remoteIt.devKey;
      axios
        .post(
          "https://api.remot3.it/apv/v27/user/login",
          { username, password },
          { headers: { developerkey } }
        )
        .then(response => {
          localStorage.setItem("token", response.data.auth_token);
          axios
            .get("https://api.remot3.it/apv/v27/device/list/all", {
              headers: { developerkey, token: localStorage.getItem("token") }
            })
            .then(response => {
              let devices = [];
              response.data.devices.map(device => {
                // console.log(device);
                devices.push({
                  active: device.devicestate === "active" ? true : false,
                  name: device.devicealias,
                  externalIp: device.devicelastip,
                  internalIp: device.lastinternalip,
                  service: device.servicetitle,
                  address: device.deviceaddress
                });
              });
              resolve({
                status: response.status,
                devices: devices
              });
            })
            .catch(error => {
              reject({
                status: error.status,
                error: error
              });
            });
        })
        .catch(error => {
          reject({
            status: error.status,
            error: error
          });
        });
    });
  }

  getDeviceAddress(deviceaddress) {
    return new Promise((resolve, reject) => {
      let username = remoteIt.username;
      let password = remoteIt.password;
      let developerkey = remoteIt.devKey;

      axios
        .post(
          "https://api.remot3.it/apv/v27/device/connect",
          { username, password, deviceaddress, wait: true },
          { headers: { developerkey, token: localStorage.getItem("token") } }
        )
        .then(response => {
          resolve({
            status: response.status,
            connection: response.data.connection
          });
        })
        .catch(error => {
          reject({
            status: error.status,
            error: error
          });
        });
    });
  }
}

export default new AxiosCalls();
