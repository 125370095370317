import React, { Component } from "react";
import { Input, Button, Message, Checkbox, Icon } from "semantic-ui-react";
import Page from "../../layouts/main";
import "./moreOptions.scss";
import { inject, observer } from "mobx-react";
//import InputPage from "../../components/NumericInput/numericInput";
import { setTemperaturethreshold } from "../../libs/devices";
import { connectToESP, getAvailableWifi, saveWifiToESP } from "../../libs/esp";
import { publishToStation } from "../../libs/mqtt";
import TimePicker from 'react-time-picker';

@inject("miscStore")
@observer
class MoreOptions extends Component {

    state = {
        devicekey: null,
        device: null,
        temperatureThreshold: 0,
        numberOfDays: 0,
        sensorsReadingsSchedule: [],
        scheduleEnabled: true,
        isErrorShow: false,
        isLoading: false,
        device: null,

        type: null,

        espSerialNumber: null,
        ssid: null,
        pass: null,
        changeWiFiRequested: false,
        availableWiFi: [],
        wifiChanged: false,
        time: '10:00'
    };

    componentDidMount() {
        let device = JSON.parse(localStorage.getItem('current_device'));
        this.setState({ device: device });
        console.log(device.numberOfDays);
        this.setState({ temperatureThreshold: device.temperatureThreshold == null || isNaN(device.temperatureThreshold) ? 0 : device.temperatureThreshold });
        this.setState({ numberOfDays: device.numberOfDays == null || isNaN(device.numberOfDays) || device.numberOfDays == undefined ? 0 : device.numberOfDays });
        this.setState({ devicekey: this.props.match.params.devicekey });
        this.setState({ type: device.type });

        this.setState({ sensorsReadingsSchedule: device.sensorsReadingsSchedule || [] });
        this.props.miscStore.setTitle("More Options");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
        console.log(this.state.type);
    }

    componentWillReceiveProps() {


        /* if (this.props.device) {
             this.setState({
                 device : this.props.device,
                 temperatureThreshold : this.props.device.temperatureThreshold
             });
         }
         */


    }


    handleSaveClick() {

        var devicekey = this.state.devicekey;
        var temperatureThreshold = this.state.temperatureThreshold;
        console.log("deviceKey", devicekey);
        console.log("temperatureThreshold", temperatureThreshold);


        // if (this.state.device) {
        var devicekey = this.state.devicekey;
        var temperatureThreshold = this.state.temperatureThreshold;
        var sensorsReadingsSchedule = this.state.sensorsReadingsSchedule;
        setTimeout(() => {
            setTemperaturethreshold({ devicekey, temperatureThreshold, sensorsReadingsSchedule })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {} else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
        // }


    }

    decreaseTempThreshold = () => {
        this.setState({ temperatureThreshold: this.state.temperatureThreshold - 1 });
    }

    increaseTempThreshold = () => {
        this.setState({ temperatureThreshold: this.state.temperatureThreshold + 1 });
    }

    decreaseNbDays = () => {
        this.setState({ numberOfDays: this.state.numberOfDays - 1 });
    }

    increaseNbDays = () => {
        console.log(this.state.numberOfDays);
        this.setState({ numberOfDays: this.state.numberOfDays + 1 });
    }

    showErrorMessage() {
        return ( <
            Message negative icon = "x"
            header = "something went wrong." /
            >
        );
    }

    onChangeTime = time => {
        this.setState({ time });

    }

    renderInputs() {

        return ( <
            div >
            <
            div className = "MoreOptions_flexdiv" >
            <
            span className = "MoreOptions_flexdiv_text" >
            Temperature Threshold <
            /span> <
            div className = "MoreOptions_flexdiv_numberInput" >
            <
            div className = "def-number-input number-input" >

            <
            i class = "minus icon whiteIcon"
            onClick = { this.decreaseTempThreshold } > < /i> <
            input className = "quantity"
            name = "quantity"
            value = { this.state.temperatureThreshold }
            type = "number" / >

            <
            i class = "plus icon whiteIcon"
            onClick = { this.increaseTempThreshold } > < /i> < /
            div > <
            /div>

            <
            /div> <
            div className = "MoreOptions_flexdiv" >
            <
            span className = "MoreOptions_flexdiv_text" >
            Number of Days <
            /span> <
            div className = "MoreOptions_flexdiv_numberInput" >
            <
            div className = "def-number-input number-input" >
            <
            i class = "minus icon whiteIcon"
            onClick = { this.decreaseNbDays } > < /i> <
            input className = "quantity"
            name = "quantity"
            value = { this.state.numberOfDays }
            onChange = {
                (newValue) => { this.setState({ numberofDays: newValue }); }
            }
            type = "number" / >
            <
            i class = "plus icon whiteIcon"
            onClick = { this.increaseNbDays } > < /i> < /
            div > <
            /div>

            <
            /div> <
            div className = "MoreOptions_flexdiv" >
            <
            span className = "MoreOptions_flexdiv_text" >
            Scheduled Sensor Readings <
            /span> <
            Checkbox

            toggle checked = { this.state.scheduleEnabled }

            value = { this.state.scheduleEnabled }
            onChange = {
                (e, { value }) => {
                    value = !value;
                    console.log("val", value);
                    this.setState({ scheduleEnabled: value })
                }
            }
            /> < /
            div >
            <
            TimePicker onChange = { this.onChangeTime }
            value = { this.state.time }
            clockIcon = { null }
            /> <
            Button color = "blue"
            onClick = {
                () => {
                    let times = this.state.sensorsReadingsSchedule;
                    if (times.indexOf(this.state.time) < 0) {
                        times.push(this.state.time);
                    }
                    this.setState({ sensorsReadingsSchedule: times });
                }
            } > Add <
            /Button><
            div className = "MoreOptions_schedule" > {
                this.state.scheduleEnabled === true ? < div > {
                    this.state.sensorsReadingsSchedule.map((item, index) => {
                        return ( <
                            div className = "MoreOptions_timeTags" > { item } < Icon name = "close"
                            onClick = {
                                () => {
                                    let times = this.state.sensorsReadingsSchedule;

                                    times.splice(index, 1);


                                    this.setState({ sensorsReadingsSchedule: times });

                                }
                            }
                            / > < /
                            div >
                        );
                    })
                } < /div > : <
                div > < /div>
            } <
            /div> { this.state.isErrorShow && this.showErrorMessage() } <
            Button color = "blue"
            onClick = {
                () => {
                    !this.state.isLoading && this.handleSaveClick();

                }
            } >
            Save <
            /Button>  < /
            div >
        )
    }

    connectToESP = async() => {

        var resp = await connectToESP();
        console.log(resp);
        if (resp.SerialNumber) {
            this.setState({ espSerialNumber: resp.SerialNumber });
            this.setState({ ssid: resp.currentSSID });
            this.setState({ pass: resp.currentPASS });
            console.log(this.state.espSerialNumber);
        }
        var av = await getAvailableWifi();
        if (av) {
            this.setState({ availableWiFi: av.AvailableWifi });
        }
    }

    saveWifi = async() => {
        await saveWifiToESP({ ssid: this.state.ssid, pass: this.state.pass });
        this.setState({wifiChanged: true});
    }

    saveWifiMQTT = async() => {
        if (this.state.device.type == 'NodeMcu') {
            console.log('NodeMCU');
            await publishToStation({ station: this.state.device.serialnumber, topic: 'IOTREE/Commands', payload: 'ChangeWiFi/ssid=' + this.state.ssid + '&pass=' + this.state.pass + '/*/' });
            this.setState({wifiChanged: true});
        } else {

        }
        
    }

    renderWiFiSettings() {
        if (this.state.device.status) {
            return ( < div >
                <
                div className = "Signup_flexdiv" >
                <
                span className = "Device1_flexdiv_text" > New SSID < /span> <
                Input fluid value = { this.state.ssid }
                onChange = {
                    (e, { value }) => this.setState({ ssid: value })
                }
                /> < /
                div > <
                div className = "Signup_flexdiv" >
                <
                span className = "Device1_flexdiv_text" > New Pass < /span> <
                Input fluid value = { this.state.pass }
                onChange = {
                    (e, { value }) => this.setState({ pass: value })
                }
                /> < /
                div > <
                Button fluid primary onClick = {
                    () => this.saveWifiMQTT()
                } >
                save <
                /Button> < /
                div >
            )
        } else if (this.state.changeWiFiRequested) {
            if (this.state.espSerialNumber === this.state.device.serialnumber) {
                return ( < div >
                    <
                    div className = "Signup_flexdiv" >
                    <
                    span className = "Device1_flexdiv_text" > Available WiFi: < /span> <
                    ol > {
                        this.state.availableWiFi.map(function(item, index) {
                            return <li className = "Device1_flexdiv_text" > { item.SSID } < /li>
                        })
                    } <
                    /ol> < /
                    div > <
                    div className = "Signup_flexdiv" >
                    <
                    span className = "Device1_flexdiv_text" > SSID < /span> <
                    Input fluid value = { this.state.ssid }
                    onChange = {
                        (e, { value }) => this.setState({ ssid: value })
                    }
                    /> < /
                    div > <
                    div className = "Signup_flexdiv" >
                    <
                    span className = "Device1_flexdiv_text" > Pass < /span> <
                    Input fluid value = { this.state.pass }
                    onChange = {
                        (e, { value }) => this.setState({ pass: value })
                    }
                    /> < /
                    div > <
                    Button fluid primary onClick = {
                        () => this.saveWifi()
                    } >
                    save <
                    /Button> < /
                    div >
                )
            } else {
                return ( < div >
                    <
                    span className = "Device1_flexdiv_text" >
                    Please connect your device wiFi to: < /span> <
                    span className = "Device1_flexdiv_text" >
                    IOTREE_ { this.state.device.serialnumber } <
                    /span> < /
                    div >
                )
            }
        } else {
            return ( < div >

                <
                /div>)
            }
        }

    render() {
        return (
            <Page className="MoreOptions">
                <div className="MoreOptions__content">
                    {this.renderInputs()}
                    {this.state.type == 'NodeMcu' ? <div className="Device1__content">

                        <div className="Device1_button Device1_flexdiv_text">
                            <Button
                                fluid
                                primary
                                onClick={() => { this.setState({ changeWiFiRequested: true }); this.connectToESP() }}
                            >
                                change WiFi
          </Button>

                        </div>
                        {this.renderWiFiSettings()}
                        {this.state.wifiChanged && ( <span className="Device1_flexdiv_text" >New wifi saved: {this.state.ssid}</span>)}
                    </div> : <div />}
                </div>
            </Page>);  }
    }

    export default MoreOptions;