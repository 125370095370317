import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Page from "../../layouts/main";
import { Input, Button, Message } from "semantic-ui-react";
import { authentication } from "../../libs/users";
import { withRouter } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";

// Images
import logo from "../../static/logo.png";
import flagFrance from "../../static/flag-france.png";
import flagLebanon from "../../static/flag-lebanon.png";
import flagUK from "../../static/flag-uk.png";

import "./Login.scss";

const LANGUAGES = [
    { name: "Arabic", code: "ar", flag: flagLebanon },
    { name: "English", code: "en", flag: flagUK },
    { name: "French", code: "fr", flag: flagFrance }
];

@inject("fireStore")
@inject("miscStore")
@inject("weavedStore")
@observer
class Login extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Please Log In");
        this.props.miscStore.setHeaderButtons({ back: false, settings: false });
        this.props.miscStore.clearToast();
    }

    state = {
        email: "",
        password: "",
        isPasswordVisible: false,
        isErrorShow: false,
        isLoading: false,
        selectedLanguage: "en"
    };




    handlePasswordToggle() {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    handleLanguageChange(languageCode) {
        this.setState({ selectedLanguage: languageCode });
    }

    handleLoginClick() {
        this.setState({ isLoading: true, isErrorShow: false });
        let isAuthenticated;

        setTimeout(() => {
            isAuthenticated = authentication({
                email: this.state.email,
                password: this.state.password
            })
                .then(results => {

                    this.setState({ isLoading: false });

                    if (results) {
                     this.props.history.replace("/user");
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
    }



    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="Incorrect email or password."
                content="Note: The email and password are both case-sensitive."
            />
        );
    }

    renderLoginForm() {
        const { isPasswordVisible, isLoading, isErrorShow } = this.state;
        return (

            <div className="Login_form">
                <Input
                    fluid
                    icon="phone"
                    iconPosition="left"
                    placeholder="phone number"
                    onChange={(e, { value }) => this.setState({ email: value })}
                />
                <Input
                    fluid
                    icon="key"
                    iconPosition="left"
                    placeholder="password"
                    type={isPasswordVisible ? "text" : "password"}
                    onChange={(e, { value }) => this.setState({ password: value })}
                    action={
                        <Button
                            circular
                            color="teal"
                            icon={isPasswordVisible ? "eye slash" : "eye"}
                            onClick={() => this.handlePasswordToggle()}
                        />
                    }
                />
                {isErrorShow && this.showErrorMessage()}
                <div className="Login__button">
                    <Button
                        fluid
                        primary
                        loading={isLoading}
                        onClick={() => !isLoading && this.handleLoginClick()}
                    >
                        LOGIN
          </Button>
                </div>
                <span className="Login__link-text">Forgot password?</span>

            </div>
        );
    }

    renderLanguages() {
        const { selectedLanguage } = this.state;
        return (
            <Fragment>
                <p className="Login__select-language">Select Language</p>
                <div className="Login__languages">
                    {LANGUAGES.map(language => (
                        <div
                            key={`language_${language.code}`}
                            className={`Login__flag${
                                selectedLanguage === language.code
                                    ? " Login__flag--selected"
                                    : " "
                                }`}
                            onClick={() => this.handleLanguageChange(language.code)}
                        >
                            <img src={language.flag} />
                            <p>{language.name}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        );
    }
    renderContacts() {
        return (
            <div className="social__container">
                <a href="https://www.linkedin.com/company/internetoftrees/" className="facebook social" >
                    <FontAwesomeIcon icon={faLinkedin} size="3x" />
                </a>

                <a href="https://www.facebook.com/IoTree1/" className="facebook social" >
                    <FontAwesomeIcon icon={faFacebook} size="3x" />
                </a>
                <a href="https://www.twitter.com/iotree1" className="twitter social">
                    <FontAwesomeIcon icon={faTwitter} size="3x" />
                </a>
                <a href="https://www.instagram.com/internetoftrees" className="instagram social" >
                    <FontAwesomeIcon icon={faInstagram} size="3x" />
                </a>
                <br></br>
                <span className="Login__text social__text">
                    Call us on <a className="Login__link-text">+961 76 179 910</a>
                    <br></br>
                    <a className="Login__link-text social__text">info@iotreesolutions.com</a>
                </span>
            </div>
        );
    }
    render() {

        const { isLoading } = this.state;
        return (
            <Page className="Login">
                <div className="Login__content" onKeyDown={(event) => { event.keyCode === 13 ? !isLoading && this.handleLoginClick(): console.log('')  }}>
                    <img src={logo} className="Login__logo" />
                    {this.renderLoginForm()}
                    {this.renderLanguages()}
                    <span className="Login__text">
                        Don't have an account? <a className="Login__link-text" href="/CreateUser">Sign up.</a>
                    </span>
                    {this.renderContacts()}
                </div>
            </Page>
        );
    }
}

export default withRouter(Login);
