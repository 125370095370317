const POSSIBLE_OPTIONS = {
  good: "green",
  average: "yellow",
  bad: "red",

  online: "green",
  offline: "red"
};

export default actualHealth => {
  const entries = Object.entries(POSSIBLE_OPTIONS);
  let color = "";
  if (!actualHealth && actualHealth !== 0) {
    return "grey";
  }

  if (typeof actualHealth === "number") {
    actualHealth > 0 && (actualHealth = "good");
    actualHealth === 0 && (actualHealth = "average");
    actualHealth < 0 && (actualHealth = "bad");
  }

  for (const [healthOption, colorOption] of entries) {
    if (healthOption === actualHealth.toLowerCase()) {
      color = colorOption;
      break;
    }
  }
  return color;
};
