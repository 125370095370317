
const usersAPIKey = 'BSWchkTyD2a5wehdaGF1K1X9CadoLjIH6zOuDpST';


export async function authentication({ email, password }) {

    if (email === "Admin" && password === "iotree") {
        localStorage.setItem("iotree_login_token", true);
        localStorage.setItem("current_user", JSON.stringify({ role: "iotree" }));
        return true
    }
    else {
        let APIresp = "null";
        const endpoint = "https://priaai72wb.execute-api.eu-west-3.amazonaws.com/users/authenticateuser?username=" + email + "&password=" + password;
        await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': usersAPIKey
            }
        })
            .then(results => results.json())
            .then(results => { APIresp = results });


        if (APIresp.Response === "Logged in") {
            localStorage.setItem("iotree_login_token", true);
            localStorage.setItem("current_user", JSON.stringify(APIresp.user));
            return true
        } else {
            return false;
        }

    }
};


export async function createUser({ phoneNumber, password, firstName,lastName, email }) {
     let APIresp = "null";
    const endpoint = "https://priaai72wb.execute-api.eu-west-3.amazonaws.com/users/createuser";

    await fetch(endpoint, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': usersAPIKey
        },
        body: JSON.stringify({
            'phoneNumber': phoneNumber,
            'email': email,
            'password': password,
            'firstName': firstName,
            'lastName': lastName
        })

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });

    

    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }

 

};



export async function setPassword({ userkey, password}) {
    let APIresp = "null";
    const endpoint = "https://priaai72wb.execute-api.eu-west-3.amazonaws.com/users/setpassword";


    console.log(userkey);
    await fetch(endpoint, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': usersAPIKey
        },
        body: JSON.stringify({
            'userkey': userkey,
            'password': password
        })

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });



    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};



export async function updateUser({ userkey, firstName, lastName, email }) {
    let APIresp = "null";
    const endpoint = "https://priaai72wb.execute-api.eu-west-3.amazonaws.com/users/updateuser";


    console.log(userkey);
    await fetch(endpoint, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': usersAPIKey
        },
        body: JSON.stringify({
            'userkey': userkey,
            'firstName': firstName,
            'lastName': lastName,
            'email': email
        })

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });



    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};
