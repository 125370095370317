import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "./Header.scss";

@inject("miscStore")
@observer
class Header extends Component {
  renderBackButton() {
    if (this.props.miscStore.showBackButton) {
      return (
        <Icon
          name="chevron left"
          size="big"
          onClick={() => this.props.history.go(-1)}
        />
      );
    } else {
      return <div />;
    }
  }

  renderSettingsButton() {
    if (this.props.miscStore.showSettingsButton) {
      return (
        <Link to="/settings" className="Layout__icon-settings">
          <Icon name="cog" size="big" />
        </Link>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <Fragment>
        <div className="Layout__top-bar">
          {this.renderBackButton()}
          <span>{this.props.miscStore.title}</span>
          {this.renderSettingsButton()}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Header);
