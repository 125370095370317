import React, { Component } from "react";
import { Input, Button, Message } from "semantic-ui-react";
import { setPassword, authentication } from "../../libs/users";
import Page from "../../layouts/main";
import { inject, observer } from "mobx-react";

@inject("miscStore")
@observer
class ChangePassword extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Create an account");
        this.props.miscStore.setHeaderButtons({ back: false, settings: false });
        this.props.miscStore.clearToast();

        this.authenticate();
    }

    state = {
        password: "",
        isPasswordVisible: false,
        isErrorShow: false,
        isLoading: false,
        firstName: "",
        lastName: "",
        phoneNumber: this.props.location.phoneNumber,
        user : null

    };

    handlePasswordToggle() {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }

    authenticate() {

        setTimeout(() => {
            authentication({
                email: this.state.phoneNumber,
                password: this.state.password
            })
                .then(results => {
                    
                    if (results) {
                        let user = JSON.parse(localStorage.getItem('current_user'));
                        this.setState({ user: user });
                        console.log(this.state.user);
                    } else {
                       
                    }
                });
        }, 1000);


    }

    handleDoneClick() {
        this.setState({ isLoading: true, isErrorShow: false });
        
        setTimeout(() => {
             setPassword({
                 userkey: this.state.user.userkey,
                password: this.state.password
            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                        this.props.history.replace("/");
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
        

    }



    showErrorMessage() {

        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content=""
            />
        );
    }

    renderInputs() {
        const { isPasswordVisible, isLoading, isErrorShow } = this.state;
        return (

            <div className="Signup_form">
                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >Phone Number</span>
                    <Input
                        fluid
                        disabled="true"
                        iconPosition="left"
                        placeholder="Enter Phone Number"
                        value={this.state.phoneNumber}
                    />
                </div>
                <div className="Signup_blockdiv">



                    <span className="Signup_blockdiv_text" >Create Password</span>
                    <Input
                        fluid
                        icon="key"
                        iconPosition="left"
                        placeholder="Enter Password"
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e, { value }) => this.setState({ password: value })}
                        action={
                            <Button
                                circular
                                color="teal"
                                icon={isPasswordVisible ? "eye slash" : "eye"}
                                onClick={() => this.handlePasswordToggle()}
                            />
                        }
                    />
                    <span className="Signup_blockdiv_text" >Confirm Password</span>
                    <Input
                        fluid
                        icon="key"
                        iconPosition="left"
                        placeholder="Enter Password"
                        type={isPasswordVisible ? "text" : "password"}
                        onChange={(e, { value }) => this.setState({ password: value })}
                        action={
                            <Button
                                circular
                                color="teal"
                                icon={isPasswordVisible ? "eye slash" : "eye"}
                                onClick={() => this.handlePasswordToggle()}
                            />
                        }
                    />
                </div>
                {isErrorShow && this.showErrorMessage()}

                    <div className="Signup__button">
                        <Button
                            fluid
                            primary
                            loading={isLoading}
                            onClick={() => !isLoading && this.handleDoneClick()}
                        >
                            DONE
          </Button>
                 
                </div>

            </div >
        )
    }




    render() {
        return (
            <Page className="Signup">
                <div className="Signup__content">
                    {this.renderInputs()}

                </div>
            </Page>);
    }
}

export default ChangePassword;
