import { observable, toJS } from "mobx";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyBvstNe7SopEgRuq9o9eFSuxD6s0z4waGo",
  authDomain: "mybugtracker-304a8.firebaseapp.com",
  databaseURL: "https://mybugtracker-304a8.firebaseio.com",
  projectId: "mybugtracker-304a8",
  storageBucket: "mybugtracker-304a8.appspot.com",
  messagingSenderId: "373427165072"
};

try {
  firebase.initializeApp(config);
} catch (err) {
  // taken from https://github.com/now-examples/next-news/blob/master/lib/db.js
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}

const fireDB = firebase.database();
const path_locations = "locations";
const path_device_indexes = "index";
const path_settings = "settings";

class FireStore {
  @observable locations = null;
  @observable deviceIndexes = null;
  @observable settings = null;
  @observable version = 0;

  constructor(initialData = {}) {
    this.getLocations();
    this.getDeviceIndexes();
    this.getSettings();
    this.getVersion();
  }

  async getLocations() {
    await fireDB.ref(path_locations).on("value", snapshot => {
      // this.locations = snapshotToArray(snapshot);
      this.findGreenhouseHealth(snapshotToArray(snapshot));
    });
  }

  async getDeviceIndexes() {
    await fireDB.ref(path_device_indexes).on("value", snapshot => {
      // this.locations = snapshotToArray(snapshot);
      this.deviceIndexes = snapshotToArray(snapshot);
    });
  }

  async getSettings() {
    await fireDB.ref(path_settings).on("value", snapshot => {
      this.settings = snapshot.val();
    });
  }

  async getVersion() {
    await fireDB.ref(path_settings + "/version").on("value", snapshot => {
      this.version = snapshot.val();
    });
  }

  findGreenhouseHealth(data) {
    data.map(locations => {
      const greenhouses = locations.greenhouses;
      Object.getOwnPropertyNames(greenhouses).map(greenhouseKey => {
        let greenhouseHealthPoints = 0;
        const devices = greenhouses[greenhouseKey].devices;
        devices &&
          Object.getOwnPropertyNames(devices).map(deviceKey => {
            switch (devices[deviceKey].health.toLowerCase()) {
              case "good":
                greenhouseHealthPoints += 1;
                break;
              case "bad":
                greenhouseHealthPoints -= 1;
                break;
              default:
                break;
            }
          });
        greenhouses[greenhouseKey].health = greenhouseHealthPoints;
      });
    });
    this.locations = data;
  }

  renameLocation({ locationKey, name }) {
    fireDB.ref(path_locations + "/" + locationKey).update({
      name: name
    });
  }

  renameGreenhouse({ locationKey, greenhouseKey, name }) {
    fireDB
      .ref(path_locations + "/" + locationKey + "/greenhouses/" + greenhouseKey)
      .update({
        name: name
      });
  }

  renameDevice({ locationKey, greenhouseKey, deviceKey, name }) {
    fireDB
      .ref(
        path_locations +
          "/" +
          locationKey +
          "/greenhouses/" +
          greenhouseKey +
          "/devices/" +
          deviceKey
      )
      .update({
        name: name
      });
  }

  async deleteLocationRecursively({ locationKey }) {
    await fireDB
      .ref(path_locations + "/" + locationKey)
      .on("value", snapshot => {
        // const greenhousesObject = snapshot.val().greenhouses;
        Object.keys(snapshot.val().greenhouses)
          .forEach(greenhouseKey =>
            this.deleteGreenhouseRecursively({ locationKey, greenhouseKey })
          )
          .then(() => this.deleteLocation({ locationKey }));
      });
  }

  async deleteGreenhouseRecursively({ locationKey, greenhouseKey }) {
    await fireDB
      .ref(path_locations + "/" + locationKey + "/greenhouses/" + greenhouseKey)
      .on("value", snapshot => {
        const devicesObject = snapshot.val().devices;
        devicesObject
          ? Object.keys(snapshot.val().devices)
              .forEach(deviceKey =>
                this.removeDeviceAssignment({
                  weavedId: devicesObject[deviceKey].weavedId
                })
              )
              .then(() => this.deleteGreenhouse({ locationKey, greenhouseKey }))
          : this.deleteGreenhouse({ locationKey, greenhouseKey });
      });
  }

  async deleteDeviceRecursively({ locationKey, greenhouseKey, deviceKey }) {
    await fireDB
      .ref(
        path_locations +
          "/" +
          locationKey +
          "/greenhouses/" +
          greenhouseKey +
          "/devices/" +
          deviceKey
      )
      .on("value", snapshot => {
        this.removeDeviceAssignment({ weavedId: snapshot.val().weavedId });
      })
      .then(() => this.deleteDevice({ locationKey, greenhouseKey, deviceKey }));
  }

  deleteLocation({ locationKey }) {
    fireDB.ref(path_locations + "/" + locationKey).remove();
  }

  deleteGreenhouse({ locationKey, greenhouseKey }) {
    fireDB
      .ref(path_locations + "/" + locationKey + "/greenhouses/" + greenhouseKey)
      .remove();
  }

  deleteDevice({ locationKey, greenhouseKey, deviceKey }) {
    fireDB
      .ref(
        path_locations +
          "/" +
          locationKey +
          "/greenhouses/" +
          greenhouseKey +
          "/devices/" +
          deviceKey
      )
      .remove();
  }

  removeDeviceAssignment({ weavedId }) {
    fireDB.ref(path_device_indexes + "/io_tree_" + weavedId + "/").update({
      deviceKey: "",
      greenhouseKey: "",
      locationKey: ""
    });
  }

  addGreenhouse({ locationKey, location, name }) {
    fireDB.ref(path_locations + "/" + locationKey + "/greenhouses/").push({
      location: location,
      name: name
    });
  }

  addDevice({ locationKey, greenhouseKey, weavedId, name }) {
    fireDB
      .ref(
        path_locations +
          "/" +
          locationKey +
          "/greenhouses/" +
          greenhouseKey +
          "/devices/"
      )
      .push({
        name: name,
        weavedId: weavedId,
        health: ""
      })
      .then(newSnap => {
        fireDB.ref(path_device_indexes + "/io_tree_" + weavedId + "/").update({
          deviceKey: newSnap.key,
          greenhouseKey: greenhouseKey,
          locationKey: locationKey
        });
      });
  }

  addAtmosphericScheduleForDevice({ weavedId, newIndex }) {
    fireDB
      .ref(path_settings + "/io_tree_" + weavedId + "/atmospheric_update_times")
      .update({ [newIndex]: "00:00" });
  }

  saveAtmosphericSettings({ weavedId, updateTimes }) {
    let arrangedUpdateTimes = {};
    updateTimes.forEach((time, index) => {
      arrangedUpdateTimes[index] = time;
    });

    fireDB
      .ref(path_settings + "/io_tree_" + weavedId + "/atmospheric_update_times")
      .update(arrangedUpdateTimes);
  }

  get readLocations() {
    return toJS(this.locations);
  }

  get readDeviceIndexes() {
    return toJS(this.deviceIndexes);
  }

  get readSettings() {
    return toJS(this.settings);
  }
}

export default FireStore;

function snapshotToArray(snapshot) {
  var returnArr = [];

  snapshot.forEach(function(childSnapshot) {
    var item = childSnapshot.val();
    item.key = childSnapshot.key;

    returnArr.push(item);
  });

  return returnArr;
}
