import React, { Component, Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";
import { inject } from "mobx-react";
import Page from "../../layouts/main";
import DevicesList from "../../components/DevicesList/DevicesList";
import EditModal from "../../components/Modals/EditModal/EditModal";

import WeekContainer from '../../components/Weather/WeekContainer';
import { getGreenhouseByKey } from "../../libs/greenhouses";
import { getDevicesByGreenhouse, getAllDevices, getLastUpdated } from "../../libs/devices";
import { getDevicesInfoForGreenhouse } from "../../libs/devices";

import { getLatestDHTTemperature } from "../../libs/sensors";

import "./greenhouse.scss";

@inject("miscStore")
class Greenhouse extends Component {


    state = {
        isLoading: false,
        greenhouse: "",
        devicesList: [],
        openEditModalWithItem: null,
        isAddDevicesModalOpen: false,
        tempWarning: false,
        tempWarningDevices: "",
    };

    componentDidMount() {
        this.props.miscStore.setTitle("Greenhouse");
        this.props.miscStore.setHeaderButtons({ back: true, settings: true });
        this.setState({ isLoading: true });
        //this.setState({ greenhouse: this.props.location.greenhouse });
        //if (this.state.greenhouse.devicesList.length > 0) { this.getDevicesList(); }
        //this.setState({ isLoading: false });
        
        this.getGreenhouse()
            .then(() => { if (this.state.greenhouse) { localStorage.setItem("current_plot", JSON.stringify(this.state.greenhouse)); }})
            .then(() => { if (this.state.greenhouse && this.state.greenhouse.devicesList.length > 0) { this.getDevicesList(); } })
            .finally(() => { this.setState({ isLoading: false }); });
            
    }

    getGreenhouse = async () => {
        let greenhousekey = this.props.match.params.greenhousekey;
        this.setState({ greenhouse: await getGreenhouseByKey({ greenhousekey }) });
        //this.setState({ greenhouse: this.props.location.greenhouse });
        let devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: this.state.greenhouse.greenhousekey });
        let updatedGreenhouse = { ...this.state.greenhouse, devicesinfo };
        this.setState({ greenhouse: updatedGreenhouse });
        console.log(this.state.greenhouse);

    }

    getDevicesList = async () => {
        let greenhousekey = this.props.match.params.greenhousekey;
        let devicesList = await getDevicesByGreenhouse({ greenhousekey })
           
        let updatedDevicesList = []
        for (const dev of devicesList) {
            let lastUpdated = await getLastUpdated({ serialnumber: dev.serialnumber });
            let latestdhttemperature = await getLatestDHTTemperature({ serialnumber: dev.serialnumber });
 
            let warningDev = this.state.tempWarningDevices;
            try {
                if (latestdhttemperature.payload.value >= dev.temperatureThreshold) {
                    this.setState({ tempWarning : true });
                    warningDev +=  dev.serialnumber + ", ";
                    this.setState({ tempWarningDevices: warningDev });

                }
            } catch{

            }
            updatedDevicesList.push({ ...dev, lastUpdated: lastUpdated.string, latestdhttemperature, tempWarning: this.state.tempWarning });
            
            }
        this.setState({ devicesList: updatedDevicesList });
    
    }

    renderModals() {
        return (
            <Fragment>
                <EditModal
                    type="Greenhouse"
                    open={this.state.openEditModalWithItem}
                    onClose={() => this.setState({ openEditModalWithItem: null })}
                    item={this.state.openEditModalWithItem}
                />
            </Fragment>
        );
    }

    renderAddButton() {
        return (
            <div className="Greenhouses_button-container" >
            <Button
                color="blue"
                    onClick={() => {
                      //  this.setState({ tempWarning: true })
                    this.props.history.push({ pathname: "/addDevice", greenhouse: this.state.greenhouse });
                }}
                    className="Greenhouses_button-add"
            >
                    <Icon name="add" className="Greenhouses_button-icon" />
                </Button>
                <div>
                    <span className="Greenhouses_button-text">Add a device</span>
                </div>
            </div>
        );
    }



    render() {
        
        if (this.state.isLoading) {
            console.log("Loading...");
            return (

                <Page className="User"></Page>
            );
        }
        else {

           // let location = { label: 'Zgharta', lon: 35.923790, lat: 34.354752 }
            
            //console.log("GREENHOUSE", this.state.greenhouse);
            //console.log("DEVICESLIST", this.state.devicesList);
            return (

                <Page className="User">
                    <div className="weatherContainer">
                        <WeekContainer
                            label={this.state.greenhouse ? this.state.greenhouse.location.label : null}
                            lon={this.state.greenhouse? this.state.greenhouse.location.lon : null}
                            lat={this.state.greenhouse ? this.state.greenhouse.location.lat : null} />
                    </div>
                    {this.state.tempWarning && this.props.miscStore.showToast({
                        type: "error",
                        title: "Warning!",
                        text1: "Temperature",
                        text2: "check " + this.state.tempWarningDevices,
                    }) }
                    <DevicesList
                        greenhouse={this.state.greenhouse}
                        devicesList={this.state.devicesList}
                        onEditClick={item => this.setState({ openEditModalWithItem: item })}
                    />
                    {this.renderModals()}
                    {this.renderAddButton()}
                </Page>
            );

        }
    }




}

export default Greenhouse;
