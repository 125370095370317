import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Page from "../../layouts/main";
import { getDevicesTreeByUser } from "../../libs/devices";
import DevicesManagerList from "../../components/DevicesManagerList/DevicesManagerList"
import "./devicesManager.scss";

@inject("fireStore")
@inject("miscStore")
@observer
class DevicesManager extends Component {
    state = {
        user: JSON.parse(localStorage.getItem('current_user')),
        devicesTreeList: []
    };

    componentDidMount() {
        this.props.miscStore.setTitle("Devices Manager");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.getDevicesList();
    }

    getDevicesList = async () => {
        let userkey = this.state.user.userkey;
        let devicesTreeList = await getDevicesTreeByUser({ userkey })

        this.setState({ devicesTreeList: devicesTreeList });

    }


    render() {
        const devicesTreeList = this.state.devicesTreeList || [];

        return (
            <Page loading={devicesTreeList.length === 0}>
                <DevicesManagerList
                    lists={devicesTreeList}
                    history={this.props.history}
                />
            </Page>
        );
    }
}

export default DevicesManager;
