import React, { Component } from "react";
import { Input, Button, Message } from "semantic-ui-react";
import { createUser } from "../../libs/users";
import Page from "../../layouts/main";
import "./createUser.scss";
import { inject, observer } from "mobx-react";

@inject("miscStore")
@observer
class CreateUser extends Component {
    componentDidMount() {
        this.props.miscStore.setTitle("Create an account");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
        this.props.miscStore.clearToast();
    }

    state = {
        email: "",
        password: "",
        isPasswordVisible: false,
        isErrorShow: false,
        isLoading: false,
        firstName: "",
        lastName: "",
        phoneNumber: "",

    };

    handlePasswordToggle() {
        this.setState({ isPasswordVisible: !this.state.isPasswordVisible });
    }


    handleCreateClick() {
        let userCreated = false;
        this.setState({ isLoading: true, isErrorShow: false });
        
        setTimeout(() => {
            userCreated = createUser({
                phoneNumber: this.state.phoneNumber,
                firstName: this.state.firstName,
                lastName: this.state.firstName,
                email: this.state.email,
                password: this.state.password
            })
                .then(results => {
                    this.setState({ isLoading: false });
                    if (results) {
                        this.props.history.replace({ pathname: "/setPassword", phoneNumber: this.state.phoneNumber});
                    } else {
                        this.setState({ isErrorShow: true });
                    }
                });
        }, 1000);
       
    }


    showErrorMessage() {
        return (
            <Message
                negative
                icon="x"
                header="something went wrong."
                content="Note: * are required cases."
            />
        );
    }

    renderInputs() {
        const { isPasswordVisible, isLoading, isErrorShow } = this.state;
        return (

            <div className="Signup_form">
                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >Phone Number</span>
                <Input
                    fluid
                    iconPosition="left"
                    placeholder="Enter Phone Number"
                    onChange={(e, { value }) => this.setState({ phoneNumber: value })}
                    />
                </div>
                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >First Name</span>
                    <Input
                        fluid
                        iconPosition="left"
                        placeholder="Enter First Name"
                        onChange={(e, { value }) => this.setState({ firstName: value })}
                    />
                </div>
                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >Last Name</span>
                    <Input
                        fluid
                        iconPosition="left"
                        placeholder="Enter Last Name"
                        onChange={(e, { value }) => this.setState({ lastName: value })}
                    />
                </div>

                <div className="Signup_flexdiv">
                    <span className="Signup_flexdiv_text" >Email<span className="Signup__optional"> (optional)</span></span>
                <Input
                    fluid
                    iconPosition="left"
                    placeholder="Enter Email Address"
                    onChange={(e, { value }) => this.setState({ email: value })}
                    />

                </div>
                {isErrorShow && this.showErrorMessage()}

                <div className="Signup__buttonContainer">
                    <Button
                        className="Signup__button"
                        fluid
                        primary
                        loading={isLoading}
                        onClick={() => !isLoading && this.handleCreateClick()}
                    >
                        Sign Up
          </Button>
                </div>

            </div >
        )
    }


    render() {
        return (
            <Page className="Signup">
                <div className="Signup__content">
                    {this.renderInputs()}
               

                <span className="Signup__text">
                    Already have an account? <a className="Signup__link-text" href="/">Sign in.</a>
                </span>
                </div>
            </Page>);
    }
}

export default CreateUser;
