import React from 'react';
import DayCard from '../../components/Weather/DayCard';

class WeekContainer extends React.Component {
    state = {
        fullData: [],
        dailyData: []
    }

    componentDidMount = () => {
        const openweatherAPIKey = '5e8646a01adca59bacd3eaecb9eae29e';
        var lat = this.props.lat;//34.354752;
        var lon = this.props.lon;//35.923790;
        const weatherURL = 'http://api.openweathermap.org/data/2.5/forecast?units=metric&lat=' + lat + '&lon=' + lon + '&APPID=' + openweatherAPIKey;
        console.log(weatherURL);
        fetch(weatherURL)
            .then(res => res.json())
            //.then(data => console.log("Data List Loaded", data.list))
            .then(data => {
                if(data.list) {
                    const dailyData = data.list.filter(reading => reading.dt_txt.includes("18:00:00"))
                    this.setState({
                        fullData: data.list,
                        dailyData: dailyData.slice(0, 3)
                    }, () => console.log(this.state))
                }
            })

    }



    formatDayCards = () => {
        return this.state.dailyData.map((reading, index) => <DayCard reading={reading} key={index} />)
    }


    render() {

      

        return (
            <div className="container">
                {/*<h1 className="display-1 jumbotron">5-Day Forecast.</h1>
                <h5 className="display-5 text-muted">{this.props.location.label}</h5>*/}

                <div className="row justify-content-center">

                    {this.formatDayCards()}

                </div>
            </div>
        )
    }
}

export default WeekContainer;