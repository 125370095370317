import React from "react";
import { Link } from "react-router-dom";
import { List, Icon, Button } from "semantic-ui-react";

import "./DevicesList.scss";




const DevicesList = ({ greenhouse, devicesList, onEditClick }) => {


    //let greenhouse = getGreenhouseByKey({ greenhousekey });
    //console.log("GREENHOUSE: ", greenhouse);
    //console.log("DevicesList: ", devicesList);
    if (!greenhouse) {
        return (<div></div>)
    }
    else if ((devicesList === undefined) || (devicesList.length == 0)) {
        return (
            <List relaxed className="DevicesList">
                <List.Item className="DevicesList_greenhouse">
                  
                        <List.Icon
                            name="leaf"
                            size="large"
                            floated="left"
                            verticalAlign="middle"
                        />
                   
                    <List.Content className="DevicesList_content">
                        <List.Header className="DevicesList_header">{greenhouse.name}</List.Header>
                        <List.Description className="DevicesList_description">
                            Location: {greenhouse.location.label}
                        </List.Description>
                        <List.Description className="DevicesList_description">
                            Nb. of Devices: {greenhouse.devicesList.length}
                            <Button
                                icon="pencil"
                                floated="right"
                                circular
                                color="orange"
                                size="tiny"
                                onClick={() => onEditClick(greenhouse)}

                            />
                        </List.Description>
                        <List.Description className="DevicesList_description">
                            Crop: {
                                greenhouse.devicesinfo.crops.map((crop, index) => { if (index == greenhouse.devicesinfo.crops.length - 1) { return crop } else { return crop + ', ' } })
                                }

                                    </List.Description>
                        <List.Description className="DevicesList_description">
                            Last updated: {greenhouse.devicesinfo.lastUpdated.string}
                                    </List.Description>
                    </List.Content>
                </List.Item>

            <h1>No devices</h1>

                </List>
        )
    }
    else {
        return (
            <div>
            <List className="DevicesList">
                <List.Item className="DevicesList_parent">
                  
                        <List.Icon
                            name="leaf"
                            size="large"
                            floated="left"
                            verticalAlign="middle"
                        />
                    <List.Content className="DevicesList_content">
                        <List.Header className="DevicesList_header">{greenhouse.name}</List.Header>
                        <List.Description className="DevicesList_description">
                            Location: {greenhouse.location.label}
                        </List.Description>
                        <List.Description className="DevicesList_description">
                            Nb. of Devices: {greenhouse.devicesList.length}
                            <Button
                                icon="pencil"
                                floated="right"
                                circular
                                color="orange"
                                size="tiny"
                                onClick={() => onEditClick(greenhouse)}

                            />
                        </List.Description>
                        <List.Description className="DevicesList_description">
                                Crop: {
                                    greenhouse.devicesinfo.crops.map((crop, index) => { if (index == greenhouse.devicesinfo.crops.length - 1) { return crop } else { return crop + ', ' } })
                                }
                                    </List.Description>
                        <List.Description className="DevicesList_description">
                                Last updated: {greenhouse.devicesinfo.lastUpdated.string}
                                    </List.Description>
                       
                          
                       
                    </List.Content>
                </List.Item>
                </List>
            
            <List className="DevicesList">
                {(devicesList.length > 0) &&
                        devicesList.map((device, index) => (
                        
                        <Link
                            key={`device_${index}`}
                            to={`/devices/${device.devicekey}`}
                            >
                            
                            <List.Item className="DevicesList_device">
                              
                                    <div>
                                    <List.Icon
                                        name="dot circle"
                                        size="large"
                                        floated="left"
                                        verticalAlign="middle"
                                        color={device.status ? "green" : "red"}
                                        />
                                        </div>
                                    <List.Content className="DevicesList__content">
                                        <List.Header className="DevicesList_header">{device.serialnumber}</List.Header>
                                        
                                        <List.Description className="DevicesList_description">
                                            Status: <span color={device.status ? "green" : "red"}>{device.status ? "Online" : "Offline"}</span>
                                            

                                        </List.Description>
                                        <List.Description className="DevicesList_description">
                                            Health: Good
                                        </List.Description>
                                        <List.Description className="DevicesList_description">
                                            {console.log(device.serialnumber + '  ' +device.crops)}
                                            Crop: {device.crops && device.crops.length ? device.crops.map((item, index) => { if (index == device.crops.length - 1) { return (item) } else { return (item + ', ') } }) : ''}
                                            
                                    </List.Description>
                                        <List.Description className="DevicesList_description">
                                            Last updated: {device.lastUpdated}
                                    </List.Description>
                                       
                                    {/*  <List.Icon
                                        class="DevicesList_Device_content-right"
                                                floated="right"
                                                name="chevron right"
                                                size="large"
                                            color="blue"/> */}
                                </List.Content>

                            </List.Item>
                                </Link>
                          
                            
                   
                        ))}






            </List>
                </div>

        );
    }


};

export default DevicesList;
