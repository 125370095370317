import { getDevicesInfoForGreenhouse } from "./devices";

const greenhouseAPIKey = '5WQPaDf6I51IYmsugLSGm3yTjx0VrKz6343UWIwy';


export async function addGreenhouse({ userkey, name, location }) {
     let APIresp = "null";
    const endpoint = "https://7zkytb7cs8.execute-api.eu-west-3.amazonaws.com/greenhouses/addgreenhouse";
    /*
    console.log(JSON.stringify({
        'userKey': userkey,
        'name': name,
        'location': location
    }));

    //let user = JSON.parse(localStorage.getItem('current_user'));
    //console.log(user);
    /*console.log(JSON.stringify({
        'userKey': user.userkey,
        'name': name,
        'location': location
    }));
    */
    
    await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': greenhouseAPIKey
        },
        body: JSON.stringify({
            'userkey': userkey,
            'name': name,
            'location': location
        })

    })
        .then(results => results.json())
        .then(results => { APIresp = results; console.log(APIresp); });

   

    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }

};

export async function getGreenhouseByKey({ greenhousekey }) {
    let APIresp = "";
    console.log("Greenhouse KEy:" + greenhousekey);
    const endpoint = "https://7zkytb7cs8.execute-api.eu-west-3.amazonaws.com/greenhouses/getgreenhousebykey?greenhousekey=" + greenhousekey;

    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': greenhouseAPIKey
        }
    })
        //.then(results => results.json())
        .then(results => {
            APIresp = results.json();
            console.log("greenhouses API res:", APIresp);
        });



    //console.log("greenhouses API res:", APIresp);
    
    //let plot = APIresp;

    //    const devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: plot.greenhousekey });
    //    plot = { ...plot, devicesinfo };
    //console.log(plot);

   // return plot;
    
    return APIresp;

};

export async function getGreenhousesByUser({ userkey }) {

    let APIresp = "[]";
    console.log(userkey)
    const endpoint = "https://7zkytb7cs8.execute-api.eu-west-3.amazonaws.com/greenhouses/getgreenhousesbyuser?userkey=" + userkey;
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': greenhouseAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    let plots = [];

    for (const plot of APIresp) {
        console.log(plot);
        const devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: plot.greenhousekey });
        console.log('Device Infos', devicesinfo);
        plots.push({ ...plot, devicesinfo });
    }
    
    return plots;

};

export async function getAllGreenhouses() {

    let APIresp = "[]";

    const endpoint = "https://7zkytb7cs8.execute-api.eu-west-3.amazonaws.com/greenhouses/getallgreenhouses";
    await fetch(endpoint, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': greenhouseAPIKey
        }
    })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);

    let plots = [];

    for (const plot of APIresp) {
        console.log(plot);
        const devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: plot.greenhousekey });
        console.log('Device Infos', devicesinfo);
        plots.push({ ...plot, devicesinfo });
    }

    return plots;


};