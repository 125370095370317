import React from "react";
import { List, Button } from "semantic-ui-react";
import EditModal from "../../components/Modals/EditModal/EditModal";


import './DevicesManagerList.scss';




const DevicesManagerList = ({ lists, history }) => {
    //console.log("GH List:", lists);
    //if (lists.length) {
    //    const devicesinfo = await getDevicesInfoForGreenhouse({ greenhousekey: lists[1].greenhousekey });
    //    console.log('Device Infos', devicesinfo);
    //}
    /*let openEditModalWithItem = null;
    const renderModals= ()=> {
        return (
            <EditModal
                type="Device"
                open={openEditModalWithItem}
                onClose={() => { openEditModalWithItem= null }}
                item={openEditModalWithItem}
            />
        );
    }
    */

    console.log('List: ', lists);
    let user = JSON.parse(localStorage.getItem('current_user'));
    if (lists.length > 0) {

        return (
            <div className="DevicesManagerList">


                <List horizontal relaxed className="DevicesManagerList_plot">
                    {
                        lists.map((plot, index) => (
                           
                            <List.Content className="DevicesManagerList_plot_content">
                                <List.Header className="DevicesManagerList_plot_header">{plot.name}<Button
                                    className="DevicesManagerList_Editbutton"
                                    icon="pencil"
                                    circular
                                    color="orange"
                                    size="tiny"
                                    onClick={() => {}}
                                /></List.Header>

                                {
                                    plot.devicesList.map((device, index) => (
                                        device != null ?

                                            <List.Item className="DevicesManagerList_device_greenhouse">
                                                <List.Content className="DevicesManagerList_device_content">
                                                    <List.Header className="DevicesManagerList_device_header">{device.name}  <Button
                                                        className="DevicesManagerList_device_EditButton"
                                                        fluid
                                                        primary
                                                        onClick={() => { console.log(device); history.push({ pathname: "/editdevice", device: device });  }}
                                                    >EDIT</Button></List.Header>
                                                    <List.Description className="DevicesManagerList_device_description">
                                                        Serial Nb: {device.serialnumber}
                                                    </List.Description>
                                                    <List.Description className="DevicesManagerList_device_description">
                                                        Type: {device.type}
                                                    </List.Description>
                                                    <List.Description className="DevicesManagerList_device_description">
                                                        Crop: {
                                                            device.crops.map((crop, index) => { if (index == device.crops.length - 1) { return crop } else { return crop + ', ' } })
                                                        }
                                                    </List.Description>

                                                </List.Content>
                                            </List.Item>
                                            : <div></div>

                                    ))
                                }
                                <List.Item className="DevicesManagerList_plot">
                                    <List.Content className="DevicesManagerList_plot_content">
                                        <List.Description className="DevicesManagerList_plot_description">
                                            <Button
                                                className="DevicesManagerList_Addbutton"
                                                fluid
                                                primary
                                                onClick={() => {
                                                    history.push({ pathname: "/addDevice", greenhouse: plot });
                                                }}
                                            >Add New Device </Button>
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            </List.Content>

                        ))
                    }
                    <List.Content className="DevicesManagerList_content">
                        <List.Description className="DevicesManagerList_description">
                            <Button
                                className="DevicesManagerList_Addbutton"
                                fluid
                                primary
                                onClick={() => { history.push({ pathname: "/addgreenhouse", userkey: user.userkey }); }}
                            >Add Plot </Button>
                        </List.Description>
                    </List.Content>
                </List>
                {
                    /* lists.map((item, index) => (
 
                         <Link key={`greenhouse_${index}`} to={{
                             pathname: `/greenhouses/${item.greenhousekey}`,
                             greenhouse: lists[index]
                         }} >
                             <List.Item className="GreenhousesList_greenhouse">
                                 <div>
                                     <List.Icon
                                         name="leaf"
                                         size="large"
                                         verticalAlign="middle"
                                     />
                                 </div>
                                 <List.Content className="GreenhousesList_content">
                                     <List.Header className="GreenhousesList_header">{item.name}</List.Header>
                                     <List.Description className="GreenhousesList_description">
                                         Location: {item.location.label}
                                     </List.Description>
                                     <List.Description className="GreenhousesList_description">
                                         Number of Devices: {item.devicesList.length}
                                     </List.Description>
                                     <List.Description className="GreenhousesList_description">
                                         Crop: {
                                             item.devicesinfo.crops.map((crop, index) => { if (index == item.devicesinfo.crops.length - 1) { return crop } else { return crop + ', ' } })
                                         }
                                     </List.Description>
                                     <List.Description className="GreenhousesList_description">
                                         Last updated: {item.devicesinfo.lastUpdated.string}
                                     </List.Description>
                                 </List.Content>
                             </List.Item>
                         </Link>
                     ))
             */
                }

            </div>
        );
    } else {
        return (<div></div>);
    }
};

export default DevicesManagerList;
