import React from "react";
import ReactDOM, {render} from "react-dom";
import { Route, BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { Provider } from "mobx-react";
// STORES
import FireStore from "./stores/FireStore";
import MiscStore from "./stores/MiscStore";
import WeavedStore from "./stores/WeavedStore";
// PAGES
import Device from "./pages/device";
//import Greenhouse from "./pages/greenhouse";
//import Greenhouses from "./pages/greenhouses";
import Login from "./pages/login";
import Settings from "./pages/settings";
import NotFound from "./pages/notFound";
import Admin from "./pages/admin";
import User from "./pages/user";
import CreateUser from "./pages/createUser";
import Greenhouse from "./pages/greenhouse";
import Device_ from "./pages/device_";
import VerifyCode from "./pages/verifyCode";
import AccountSettings from "./pages/accountSettings";
import ChangePassword from "./pages/changePassword";
import MoreOptions from "./pages/moreOptions";
import setPassword from "./pages/setPassword";
import addGreenhouse from "./pages/addGreenhouse";
import addDevice from "./pages/addDevice";
import editDevice from "./pages/editDevice";
import DevicesManager from "./pages/devicesManager";

import * as serviceWorker from "./serviceWorker";
import "./index.css";

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();
//let location = useLocation();


serviceWorker.unregister();

const fireStore = new FireStore();
const miscStore = new MiscStore();
const weavedStore = new WeavedStore();

export default class App extends React.Component {
 /*
    
    app = document.getElementById('app');
    if (app) {
        // 1. Set up the browser history with the updated location
        // (minus the # sign)
        const path = (/#!(\/.*)$/.exec(useLocation().hash) || [])[1];
        console.log(path);
        if (path) {
            history.replace(path);
        }
    
        // 2. Render our app
        render(<App />, app);
    }
*/
   render(){
    /*
    const path = (/#!(\/.*)$/.exec(useLocation().hash) || [])[1];
    console.log(path);
    if (path) {
        history.replace(path);
    }*/
   // let location = useLocation();
        return(
            <Provider
        fireStore={fireStore}
        miscStore={miscStore}
        weavedStore={weavedStore}
    >
      <Router>
            <div>
                <link
                    rel="stylesheet"
                    href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"

                />
                <link href="/owfont-master/css/owfont-regular.css" rel="stylesheet" type="text/css" />
                <Switch>

                    <Route exact path="/" component={Login} />
                    <Route exact path="/moreoptions/:devicekey" component={MoreOptions} />
                    <Route exact path="/changePassword" component={ChangePassword} />
                    <Route exact path="/setPassword" component={setPassword} />
                    <Route exact path="/VerifyCode" component={VerifyCode} />
                    <Route exact path="/AccountSettings" component={AccountSettings} />
                    <Route exact path="/settings" component={Settings} />
                    <Route exact path="/Admin" component={Admin} />
                    <Route exact path="/User" component={User} />
                    <Route exact path="/CreateUser" component={CreateUser} />
                    <Route path="/addgreenhouse" component={addGreenhouse} />
                    <Route path="/adddevice" component={addDevice} />
                    <Route path="/editdevice" component={editDevice} />
                    <Route path="/devicesmanager" component={DevicesManager} />
                    
                    <Route path="/greenhouses/:greenhousekey" component={Greenhouse} />
                    <Route path="/devices/:devicekey" component={Device_} />
                    
                    <Route component={NotFound} />
                </Switch>
            </div>
        </Router>  
        </Provider>);
   }
}
//ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//export default routing;