import React, { Component, Fragment } from "react";
import Page from "../../layouts/main";
import { inject } from "mobx-react";
import { getGreenhousesByUser, getAllGreenhouses } from "../../libs/greenhouses";
import { Button, Icon } from "semantic-ui-react";
import GreenhousesList from "../../components/GreenhousesList/GreenhousesList";
import "./user.scss";

@inject("miscStore")
class User extends Component {
    state = {
        isDevicesModalOpen: false,
        isErrorShow: false,
        isLoading: true,
        greenhousesList: {},
        user: JSON.parse(localStorage.getItem('current_user'))
    };

    getGreenhouses = async () => {
        if (this.state.user.role === "admin") {
            this.setState({ greenhousesList: await getAllGreenhouses() })
        } else {
            this.setState({ greenhousesList: await getGreenhousesByUser({ userkey: this.state.user.userkey }) })
        }
        this.setState({ isLoading: false })
    }


    componentDidMount() {
        this.props.miscStore.setTitle("My Plots");
        this.props.miscStore.setHeaderButtons({ back: true, settings: true });
        this.setState({ isLoading: true })
        this.getGreenhouses();
        

    }


    renderAddButton() {
        return (
            <div className="Greenhouses_button-container" >
            <Button
                color="blue"
                    onClick={() => {
                        
                        this.props.history.push({ pathname: "/addgreenhouse", userkey: this.state.user.userkey } );
                    //this.setState({ isDevicesModalOpen: true });
                }}
                className="Greenhouses_button-add"
            >
                    <Icon name="add" className="Greenhouses_button-icon"/>
                </Button>
                <div>
                    <span className="Greenhouses_button-text">Add a plot</span>
                    </div>
                </div>
        );
    }


    render() {
        

        return (
            <Page className="User" loading={this.state.isLoading}>
                    <div className="User_heading">
                        <span >Welcome {this.state.user.firstName} {this.state.user.lastName}</span>
                        {this.state.user.role == 'Admin'? 'Admin' :''}
                    </div>
                    <GreenhousesList
                        lists={this.state.greenhousesList}
                        user={this.state.user}
                    />

                    {this.renderAddButton()}



            </Page>);
    }
}

export default User;
