
const mqttAPIKey = 'DBindlyTms8a9IF1NIKi61V05Zyq7Gjc2oTAWC91';




export async function publishToStation({ station,topic, payload}) {
    let APIresp = "null";
    const endpoint = "https://qdpfr1o0bh.execute-api.eu-west-3.amazonaws.com/mqtt/publishtostation";

    console.log(JSON.stringify({
        'topic': topic + '/' + station,
        'payload': payload
    }));


    await fetch(endpoint, {
        mode: 'cors',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': mqttAPIKey
        },
        body: JSON.stringify({
            'topic': topic + '/' + station,
            'payload': payload
        })

    })
        .then(results => results.json())
        .then(results => { console.log(results); APIresp = results; });



    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};
