import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import "./LoaderSpinner.scss";

const LoaderSpinner = ({ loading }) => {
  return (
    <Dimmer active={loading}>
      <Loader size="big">Loading...</Loader>
    </Dimmer>
  );
};

export default LoaderSpinner;
