import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import { Button, Input } from "semantic-ui-react";
import Page from "../../layouts/main";


import "./verification.scss";


@inject("miscStore")
@observer
class Settings extends Component {
    state = {

    };

    async componentDidMount() {
        this.props.miscStore.setTitle("Verification code");
        this.props.miscStore.setHeaderButtons({ back: true, settings: false });
    }

 

    renderVerification() {
        return (
            <div className="Verification_form">
                <div className="Verification__text">
                    <span >Verification Code</span>
                </div>

                <div className="Verification__description">
                    <p>A temporary verification code has been sent to the mobile number provided  by SMS.</p>
                    <p>Please check your phone now and enter the code below</p>
                </div>

                <div className="Verification__Input">
                    <Input
                        fluid
                        onChange={(e, { value }) => { }}
                    />

                    <Input
                        fluid
                        onChange={(e, { value }) => { }}
                    />
                    <Input
                        fluid
                        onChange={(e, { value }) => { }}
                    />
                    <Input
                        fluid
                        onChange={(e, { value }) => { }}
                    />
                    </div>
                <div className="Verification__button">
                    <Button
                        fluid
                        primary
                        onClick={() => { }}
                    >
                        Verify
          </Button>
                </div>
                <div>
                    <span className="Verification__link-text">Resend code</span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <Page className="Verification__content" loading={false}>

                {this.renderVerification()}

            </Page>
        );
    }
}

export default Settings;
