import React, { Fragment, Component } from "react";
import { inject, observer } from "mobx-react";
import Page from "../../layouts/main";
import healthColor from "../../libs/healthColor";
import {
  List,
  Icon,
  Dropdown,
  Checkbox,
  Input,
  Button
} from "semantic-ui-react";
import locationsList from "../../fixtures/locationsList";
import InsectsList from "../../fixtures/InsectsList";
import CameraFeedModal from "../../components/Modals/CameraFeedModal/CameraFeedModal";
import ReadingsChart from "../../components/ReadingsChart/ReadingsChart";
import EditModal from "../../components/Modals/EditModal/EditModal";
import "./device.scss";

@inject("fireStore")
@inject("miscStore")
@inject("weavedStore")
@observer
class Device extends Component {
  state = {
    selectedFlies: null,
    threshold: null,
    deviceLocation: null,
    selectedPool: null,
    isDataFilled: false,
    isCameraModalOpen: false,
    weavedId: null,
    openEditModalWithItem: null
  };

  componentDidMount() {
    this.props.miscStore.setTitle("Device");
    this.props.miscStore.setHeaderButtons({ back: true, settings: true });
  }

  toggleCameraModal(boolValue) {
    this.setState({ isCameraModalOpen: boolValue });
  }

  renderDeviceInfo(device) {
    const weavedStore = this.props.weavedStore;

    return (
      <List relaxed className="Device">
        <List.Item className="Device__parent">
          <List.Icon
            name="dot circle"
            color={healthColor("bad")}
            size="large"
            verticalAlign="middle"
          />
          <List.Content className="Device__content">
            <List.Header>{device.name}</List.Header>
            <List.Description>
              Geotag (LAT, LNG): {device.lat}, {device.lng}
            </List.Description>
          </List.Content>
          <List.Content floated="right" className="Device__content-right">
            <Button
              className="GreenhousesList__edit-name"
              icon="pencil"
              circular
              color="orange"
              size="tiny"
              onClick={() =>
                this.setState({
                  openEditModalWithItem: {
                    ...device,
                    ...this.props.match.params
                  }
                })
              }
            />
            <Icon
              inverted
              circular
              link
              name="camera retro"
              size="large"
              color="blue"
              onClick={() => this.toggleCameraModal(true)}
              loading={weavedStore.isLoading}
              disabled={weavedStore.isLoading}
            />
          </List.Content>
        </List.Item>
      </List>
    );
  }

  renderOptions(device) {
    let { selectedFlies, threshold, deviceLocation, selectedPool } = this.state;

    const deviceStatus = device.status || "N/A";
    const deviceHealth = device.health || "N/A";

    const latestTemperatureKey = device.temperatures
      ? Object.keys(device.temperatures)[
          Object.keys(device.temperatures).length - 1
        ]
      : {};

    const lastestHumiditiesKey = device.humidities
      ? Object.keys(device.humidities)[
          Object.keys(device.humidities).length - 1
        ]
      : {};

    const currentTemp = device.temperatures
      ? device.temperatures[latestTemperatureKey].current
        ? device.temperatures[latestTemperatureKey].current.toFixed(1)
        : 0
      : "N/A";

    const currentTemp2 = device.temperatures
      ? device.temperatures[latestTemperatureKey].current2
        ? device.temperatures[latestTemperatureKey].current2.toFixed(1)
        : 0
      : "N/A";
      /*
    const latestMinTemp = device.temperatures
      ? device.temperatures[latestTemperatureKey].min
        ? device.temperatures[latestTemperatureKey].min.toFixed(1)
        : 0
      : "N/A";

    const latestMaxTemp = device.temperatures
      ? device.temperatures[latestTemperatureKey].max
        ? device.temperatures[latestTemperatureKey].max.toFixed(1)
        : 0
      : "N/A";
      */
    const currentHumidity = device.humidities
      ? device.humidities[lastestHumiditiesKey].current
        ? device.humidities[lastestHumiditiesKey].current.toFixed(1)
        : 0
      : "N/A";

    const currentHumidity2 = device.humidities
      ? device.humidities[lastestHumiditiesKey].current2
        ? device.humidities[lastestHumiditiesKey].current2.toFixed(1)
        : 0
      : "N/A";
      /*
    const latestMinHumid = device.humidities
      ? device.humidities[lastestHumiditiesKey].min
        ? device.humidities[lastestHumiditiesKey].min.toFixed(1)
        : 0
      : "N/A";

    const latestMaxHumid = device.humidities
      ? device.humidities[lastestHumiditiesKey].min
        ? device.humidities[lastestHumiditiesKey].min.toFixed(1)
        : 0
      : "N/A";
      */
    return (
      <div className="Device__options">
        <div className="Device__last-seen">
          {/* Last Updated 25/05/2019 - 9:41 AM */}
          Last Updated: {device.last_updated || "N/A"}
        </div>
        <div className="Device__datalist">
          <div className="Device__data">
            <span>Status</span>{" "}
            <b style={{ color: healthColor(deviceStatus) }}>{deviceStatus}</b>
          </div>
          <div className="Device__data">
            <span>Health</span>{" "}
            <b style={{ color: healthColor(deviceHealth) }}>{deviceHealth}</b>
          </div>
          <div className="Device__data">
            <span>Insects Detected</span> <b>{device.insects_count || "N/A"}</b>
          </div>
          <br />
          <div className="Device__data">
            <span>Temperature</span> <b>{`${currentTemp} ° C`}</b>
          </div>
          <div className="Device__data">
            <span>Temperature 2</span> <b>{`${currentTemp2} ° C`}</b>
          </div>
          <div className="Device__data">
            <span>Humidity</span> <b>{`${currentHumidity} %`}</b>
          </div>
          <div className="Device__data">
            <span>Humidity 2</span> <b>{`${currentHumidity2} %`}</b>
          </div>
          <div className="Device__data">
            <span>Light</span> <b>N/A</b>
          </div>
          <div className="Device__last-seen">
            {/* Last Updated 25/05/2019 - 9:41 AM */}
            Last Updated: {device.last_updated_atmospheric || "N/A"}
          </div>
        </div>
        <div className="Device__input-fields">
          <span>Pool</span>
          <Dropdown
            placeholder="Select Location"
            selection
            search
            options={locationsList}
            value={selectedPool}
            onChange={e => this.setState({ selectedPool: e.target.value })}
          />
        </div>
        <div className="Device__input-fields">
          <span>Target</span>
          <Dropdown
            placeholder="Select Fly"
            selection
            options={InsectsList}
            value={selectedFlies}
            onChange={e => this.setState({ selectedFlies: e.target.value })}
          />
        </div>
        <div className="Device__input-fields">
          <span>Threshold</span>
          <Input
            type="number"
            placeholder="00"
            value={threshold}
            onChange={e => this.setState({ threshold: e.target.value })}
          />
        </div>
        <div className="Device__input-fields">
          <span>Location</span>
          <Input
            placeholder="Custom Location"
            value={deviceLocation}
            onChange={e => this.setState({ deviceLocation: e.target.value })}
          />
        </div>
        <div className="Device__screenshot-scheduler">
          <div>
            <span>Scheduled Screenshot</span>
            <br />
            <span className="Device__link-text">Daily at 05:00 AM</span>
          </div>
          <Checkbox toggle />
        </div>
      </div>
    );
  }

  renderTemperatureChart(temperatures) {
    return <ReadingsChart title="Temperature" data={temperatures || {}} />;
  }

  renderHumidityChart(humidities) {
    return <ReadingsChart title="Humidity" data={humidities || {}} />;
  }

  renderFloatingButtons() {
    return (
      <Fragment>
        <Button
          className="Device__button-floating Device__button-save"
          circular
          color="blue"
          icon="save"
          size="massive"
        />
        {/* <Button
          className="Device__button-floating Device__button-delete"
          circular
          color="red"
          icon="trash"
          size="massive"
        /> */}
      </Fragment>
    );
  }

  renderModals() {
    return (
      <EditModal
        type="Device"
        open={this.state.openEditModalWithItem}
        onClose={() => this.setState({ openEditModalWithItem: null })}
        item={this.state.openEditModalWithItem}
      />
    );
  }

  render() {
    const {
      selectedFlies,
      deviceLocation,
      selectedPool,
      isDataFilled,
      threshold,
      isCameraModalOpen,
      weavedId
    } = this.state;

    const query = this.props.match.params;

    const locations = this.props.fireStore.readLocations;
    const locationGreenhouses = locations
      ? locations.find(e => e.key === query.locationKey)
      : null;
    const devices = locationGreenhouses
      ? locationGreenhouses.greenhouses[query.greenhouseKey]
      : null;
    const device = devices ? devices.devices[query.deviceKey] : [];

    // devices && this.props.miscStore.setTitle(device.name);

    if (device && locationGreenhouses && !isDataFilled) {
      this.setState({ isDataFilled: true });
      !selectedFlies && this.setState({ selectedFlies: device.target });
      !deviceLocation && this.setState({ deviceLocation: device.location });
      !selectedPool &&
        this.setState({
          selectedPool:
            locationGreenhouses.greenhouses[query.greenhouseKey].location
        });
      !threshold && this.setState({ threshold: device.threshold });
      !weavedId &&
        this.setState({ weavedId: device.weavedId }, () =>
          this.props.weavedStore.setWeavedDeviceId(device.weavedId)
        );
    }

    return (
      <Page loading={device.length === 0}>
        {isCameraModalOpen && (
          <CameraFeedModal
            onClose={() => this.toggleCameraModal(false)}
            deviceName={device.name}
            feedLink={this.props.weavedStore.requestedAddresses._feed}
            onCount={() => {
              this.props.weavedStore.countInsects();
              this.toggleCameraModal(false);
            }}
          />
        )}
        {this.renderDeviceInfo(device)}
        {this.renderOptions(device)}
        {this.renderTemperatureChart(device.temperatures)}
        {this.renderHumidityChart(device.humidities)}
        <div className="Device__logs-and-screenshots">
          <div>Logs & Screenshots</div>
          <Icon name="chevron right" />
        </div>
        {this.renderFloatingButtons()}
        {this.renderModals()}
      </Page>
    );
  }
}

export default Device;
