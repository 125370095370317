const devicesAPIKey = 'h1iOkOvgZTa0AyPCagV3x21YiiSt9G8o4fBL1UWy';

export async function addDevice_fn({ userkey, plotkey, serialnumber, name, role, type, typeofSoil, plotName, plotLocation, crops, Camera, DHTtemperature, DHThumidity, LM35temperature, DS18temperature, BHVlight, Moisture1, Moisture2 }) {
    let APIresp = "null";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/adddevice";
    console.log(JSON.stringify({
        'userkey': userkey,
        'greenhousekey': plotkey,
        'serialnumber': serialnumber,
        'name': name,
        'role': role,
        'type': type,
        'typeofSoil': typeofSoil,
        'plotName': plotName,
        'plotLocation': plotLocation,
        'crops': crops,
        'Camera': Camera,
        'DHTtemperature': DHTtemperature,
        'LM35temperature': LM35temperature,
        'DHThumidity': DHThumidity,
        'DS18temperature': DS18temperature,
        'BHVlight': BHVlight,
        'Moisture1': Moisture1,
        'Moisture2': Moisture2
    }));

    await fetch(endpoint, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            },
            body: JSON.stringify({
                'userkey': userkey,
                'greenhousekey': plotkey,
                'serialnumber': serialnumber,
                'name': name,
                'role': role,
                'type': type,
                'typeofSoil': typeofSoil,
                'plotName': plotName,
                'plotLocation': plotLocation,
                'crops': crops,
                'Camera': Camera,
                'DHTtemperature': DHTtemperature,
                'LM35temperature': LM35temperature,
                'DS18temperature': DS18temperature,
                'DHThumidity': DHThumidity,
                'BHVlight': BHVlight,
                'Moisture1': Moisture1,
                'Moisture2': Moisture2
            })

        })
        .then(results => results.json())
        .then(results => {
            APIresp = results;
            console.log(APIresp);
        });

    // APIresp = "SUCCESS";

    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};



export async function updateDevice({ devicekey, name, role, type, typeofSoil, DHTtemperature, DHThumidity, LM35Temperature, BHVlight, Moisture1, Moisture2, DS18temperature }) {
    let APIresp = "null";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/updatedevice";

    console.log(JSON.stringify({
        'devicekey': devicekey,
        'name': name,
        'role': role,
        'type': type,
        'typeofSoil': typeofSoil,
        'DHTtemperature': DHTtemperature,
        'DHThumidity': DHThumidity,
        'LM35Temperature': LM35Temperature,
        'BHVlight': BHVlight,
        'Moisture1': Moisture1,
        'Moisture2': Moisture2,
        'DS18temperature': DS18temperature
    }));


    await fetch(endpoint, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            },
            body: JSON.stringify({
                'devicekey': devicekey,
                'role': role,
                'type': type,
                'typeofSoil': typeofSoil,
                'DHTtemperature': DHTtemperature,
                'DHThumidity': DHThumidity,
                'LM35Temperature': LM35Temperature,
                'BHVlight': BHVlight,
                'Moisture1': Moisture1,
                'Moisture2': Moisture2,
                'DS18temperature': DS18temperature
            })

        })
        .then(results => results.json())
        .then(results => {
            APIresp = results;
            console.log(APIresp);
        });



    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};



export async function setTemperaturethreshold({ devicekey, temperatureThreshold, sensorsReadingsSchedule }) {
    let APIresp = "null";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/settemperaturethreshold";

    console.log(JSON.stringify({
        'devicekey': devicekey,
        'temperatureThreshold': temperatureThreshold,
        'sensorsReadingsSchedule': sensorsReadingsSchedule
    }));


    await fetch(endpoint, {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            },
            body: JSON.stringify({
                'devicekey': devicekey,
                'temperatureThreshold': temperatureThreshold,
                'sensorsReadingsSchedule': sensorsReadingsSchedule
            })

        })
        .then(results => results.json())
        .then(results => {
            APIresp = results;
            console.log(APIresp);
        });



    if (APIresp === "SUCCESS") {
        return true
    } else {
        return false;
    }



};

export async function getDeviceByKey({ devicekey }) {
    let APIresp = "";

    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getdevice?devicekey=" + devicekey;
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => { APIresp = results.Item; });
    console.log("getDeviceByKey", APIresp);
    return APIresp;

};

export async function getDevicesByGreenhouse({ greenhousekey }) {

    let APIresp = "[]";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getdevicesbygreenhouse?greenhousekey=" + greenhousekey;
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log("devices API res:", APIresp);
    return APIresp;

};


export async function getDevicesInfoForGreenhouse({ greenhousekey }) {
    let devicesList = await getDevicesByGreenhouse({ greenhousekey });
    console.log(devicesList);
    try {
        let crops = [];
        let lastUpdated = { time: 0, string: 'N/A' };
        for (const dev of devicesList) {
            let lastUpdateddev = await getLastUpdated({ serialnumber: dev.serialnumber });
            if (lastUpdateddev.time > lastUpdated.time) {
                lastUpdated = lastUpdateddev;
            }

            for (const crop of dev.crops) {
                var index = crops.findIndex(c => c == crop)
                if (index === -1) {
                    crops.push(crop);
                }
            }
        }

        //console.log(lastUpdated);
        //console.log(crops);
        return { lastUpdated, crops }
    } catch {
        return { lastUpdated: { time: 0, string: 'N/A' }, crops: [] }
    }
}


export async function getAllDevices() {

    let APIresp = "[]";

    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getalldevices";
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log(APIresp);
    return APIresp;

};



export async function getConnectedDevicesToStation({ serialnumber }) {

    let APIresp = "[]";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getconnecteddevicestostation?serialnumber=" + serialnumber;
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    console.log("devices API res:", APIresp);
    return APIresp;

};



export async function getLastUpdated({ serialnumber }) {

    console.log(serialnumber);
    let APIresp = "0";
    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getlastupdated?serialnumber=" + serialnumber;
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => {
            if (results.errorMessage) { APIresp = { time: 0, string: 'N/A' }; } else {
                APIresp = { time: results, string: ConvertEpochToDateTime(results) };
            }
        });
    //.then(results => { if (results.errorMessage) { APIresp = 'N/A'; } else { APIresp = ConvertEpochToDateTime(results); } });
    return APIresp;

};


export async function getDevicesTreeByUser({ userkey }) {
    let APIresp = "";

    const endpoint = "https://teqzjgfswb.execute-api.eu-west-3.amazonaws.com/devices/getdevicestreebyuser?userkey=" + userkey;
    await fetch(endpoint, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': devicesAPIKey
            }
        })
        .then(results => results.json())
        .then(results => { APIresp = results; });
    return APIresp;

};



const ConvertEpochToDateTime = (epoch) => {
    if (epoch !== undefined) {
        var d = String(new Date(parseInt(epoch)));

        d = d.substring(0, d.indexOf("GMT"));
        return d;
    } else {
        return "N/A";
    }
}