import React, { Component } from "react";
import Header from "../components/Header/Header";
import Toast from "../components/Toast/Toast";
import LoaderSpinner from "../components/LoaderSpinner/LoaderSpinner";
import { withRouter } from "react-router";
// import Footer from "../components/Footer/Footer";
import "./main.scss";

class Page extends Component {
  componentWillMount() {
    if (!localStorage.getItem("iotree_login_token")) {
       // this.props.history.push("/");
        this.props.history.replace("/");
    }
  }

  render() {
    const { children, loading, ...extra } = this.props;
    return (
      <div className="MainLayout" {...extra}>
        <Header />
        <Toast />
        <LoaderSpinner loading={loading} />
        {children}
        {/* <Footer /> */}
      </div>
    );
  }
}

export default withRouter(Page);
