import React, { Fragment } from "react";
import { Button } from "semantic-ui-react";
import { CSVLink } from "react-csv";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // ReferenceLine,
  ResponsiveContainer
} from "recharts";
import "./ReadingsChart.scss";

// const COLD_THRESHOLD = 1000;
// const HOT_THRESHOLD = 3500;

const COLD_COLOR = "#0000FF";
const HOT_COLOR = "#FF0000";

// const data = [
//   {
//     name: "Day A",
//     min: 4000,
//     max: 2400
//   },
//   {
//     name: "Day B",
//     min: 3000,
//     max: 1398
//   },
//   {
//     name: "Day C",
//     min: 2000,
//     max: 9800
//   }
// ];

const organizeData = data => {
  return Object.keys(data).map(day => {
    let fallbackVal = 0;

    data[day].min && (fallbackVal = data[day].min);
    data[day].max && (fallbackVal = data[day].max);

    return {
      name: day,
      min: data[day].min ? data[day].min.toFixed(1) : fallbackVal,
      max: data[day].max ? data[day].max.toFixed(1) : fallbackVal
    };
  });
};

const ReadingsChart = ({ title, data }) => {
    console.log(title, data);
  const organizedData = organizeData(data);
  console.log(title, organizedData);

  let csvData = [["Date", `Minimum ${title}`, `Maximum ${title}`]];

  organizedData.forEach(data => {
    csvData.push([new Date(data.name), data.min, data.max]);
  });

  return (
    <Fragment>
      {title && (
        <h2 className="ReadingsChart__title">
          {title}{" "}
          <CSVLink data={csvData} filename={`IOTree ${title} Readings.csv`}>
            <Button color="green" circular icon="download" />
          </CSVLink>
        </h2>
      )}
      <ResponsiveContainer className="ReadingsChart__responsive-container">
        <AreaChart
          label="Eyyooo"
          width={600}
          height={200}
          data={organizedData}
          margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorMin" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={COLD_COLOR} stopOpacity={0.8} />
              <stop offset="95%" stopColor={COLD_COLOR} stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colordata2" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={HOT_COLOR} stopOpacity={0.8} />
              <stop offset="95%" stopColor={HOT_COLOR} stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          {/* <ReferenceLine
          y={HOT_THRESHOLD}
          label="Hot"
          stroke="red"
          strokeDasharray="3 3"
        />
        <ReferenceLine
          y={COLD_THRESHOLD}
          label="Cold"
          stroke="blue"
          strokeDasharray="3 3"
        /> */}
          <Area
            type="monotone"
            dataKey="max"
            stroke={HOT_COLOR}
            fill="url(#colordata2)"
          />
          <Area
            type="monotone"
            dataKey="min"
            stroke={COLD_COLOR}
            fill="url(#colorMin)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export default ReadingsChart;
